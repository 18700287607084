import { useCallback, useEffect, useMemo } from "react";

import usePrevious from "../../../../hooks/usePrevious";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import regionsActions from "../../regions/actions";
import actions from "../../regions/actions";
import { REGION_COUNTRY_CODE } from "../../regions/api/scheme";

export const useRegions = () => {
  const dispatch = useAppDispatch();
  const districtsSlice = useAppSelector((state) => state.regions.districts);
  const regionsSlice = useAppSelector((state) => state.regions.regions);

  const prevDistrictsFetching = usePrevious(districtsSlice.fetching);

  const fetching = districtsSlice.fetching || regionsSlice.fetching;
  const districts = useMemo(
    () =>
      districtsSlice.items?.filter(
        (district) => !["96", "97"].includes(district.code)
      ),
    [districtsSlice.items]
  );
  const regions = regionsSlice.items;
  const districtIds = useMemo(
    () => districts?.map((district) => district.id) ?? [],
    [districts]
  );
  const regionIds = useMemo(
    () => regions?.map((region) => region.id) ?? [],
    [regions]
  );

  const country = useMemo(
    () => regions?.find((region) => region.code === REGION_COUNTRY_CODE),
    [regions]
  );

  useEffect(() => {
    if (prevDistrictsFetching && !districtsSlice.fetching) {
      dispatch<any>(actions.regions.get(districtIds));
    }
  }, [dispatch, districtIds, districtsSlice.fetching, prevDistrictsFetching]);

  const requestRegions = useCallback(() => {
    dispatch<any>(regionsActions.districts.get());
  }, [dispatch]);

  const getDistrictByRegion = useCallback(
    (regionCode: string) =>
      regions?.find((region) => region.code === regionCode)?.parentId[0],
    [regions]
  );

  return {
    fetching,
    country,
    districts: districts ?? [],
    regions: regions ?? [],
    districtIds,
    regionIds,
    requestRegions,
    getDistrictByRegion,
  };
};
