import clsx from "clsx";

import { TextValue } from "../TextValue/TextValue";
import * as styles from "./ProgressBar.module.scss";

export enum ProgressColors {
  "yellow" = "yellow",
  "red" = "red",
  "orange" = "orange",
  "green" = "green",
  "blue" = "blue",
}

export enum ArrowDirection {
  "right" = "right",
  "left" = "left",
}

export type ProgressSegment = {
  value: number;
  color?: ProgressColors;
  disabled?: boolean;
  arrow?: boolean;
  arrowTransparent?: boolean;
  arrowDirection?: ArrowDirection;
};

export type ProgressBarProps = {
  segments: ProgressSegment[];
  max?: number;
  value?: number;
  color?: ProgressColors;
  noBackground?: boolean;
  inlineValue?: boolean;
  position?: number;
};

const Segment: React.FC<ProgressSegment & { max: number }> = ({
  color,
  arrowTransparent,
  disabled,
  value,
  arrowDirection,
  max,
  arrow,
}) => (
  <div
    className={clsx(
      color && styles[color],
      arrowTransparent && styles.bgTransparent,
      disabled && styles.disabled
    )}
    style={{
      width: `${((100 / max) * value).toFixed(5)}%`,
    }}
  >
    {arrow && (
      <div
        className={clsx(
          styles.arrow,
          styles[arrowDirection ?? ArrowDirection.right],
          arrowTransparent &&
            `${color ? styles[color] : ""} ${styles.arrowTransparent}`
        )}
      />
    )}
  </div>
);

export const ProgressBar: React.FC<ProgressBarProps> = ({
  segments,
  max,
  value,
  color,
  noBackground,
  inlineValue,
  position,
}) => {
  const minVal = 0;
  const maxVal = max ?? segments.reduce((sum, { value }) => sum + value, 0);
  const length = maxVal - minVal;
  const posPercent = position ? (position * 100) / length : 0;

  return (
    <div className={clsx(styles.bar, noBackground && styles.barTransparent)}>
      {segments.map((s, i) => (
        <Segment {...s} max={maxVal} key={i} />
      ))}
      {inlineValue && value && <TextValue extraValue={value} extraIcon />}
      {value && !inlineValue && color && (
        <div
          className={`${styles.value} ${styles[color]}`}
          style={{
            left: `${((100 / maxVal) * value).toFixed(5)}%`,
          }}
        >
          {value}
        </div>
      )}
      {position && (
        <div className={styles.position} style={{ left: `${posPercent}%` }}>
          {position}
        </div>
      )}
    </div>
  );
};
