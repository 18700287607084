import { Card, Spin, Typography } from "antd";
import React, { FC, useMemo } from "react";

import { ILightTableRow } from "../../core/scheme/olap";
import { useAppSelector } from "../../redux/hooks";
import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import {
  LIGHTS_MAP_TYPES,
  LIGHTS_TYPES,
} from "../../redux/slices/lights/api/scheme";
import { Empty } from "../Empty";
import { ProgressBar, ProgressColors } from "../ProgressBar/ProgressBar";
import * as styles from "./CardSubjectRating.module.scss";

export interface ICardSubjectRatingProps {
  id: string;
}

const ALLOWED_LIGHTS = (
  Object.keys(LIGHTS_MAP_TYPES) as (keyof typeof LIGHTS_MAP_TYPES)[]
).filter(
  (lightId) =>
    ![
      LIGHTS_TYPES.mortgageGov,
      LIGHTS_TYPES.mortgageGovBenefits,
      LIGHTS_TYPES.mortgageGovFamily,
      LIGHTS_TYPES.mortgageFarEast,
      LIGHTS_TYPES.mortgageVillage,
      LIGHTS_TYPES.mortgagePrimary,
      LIGHTS_TYPES.roadsDead,
    ].includes(LIGHTS_MAP_TYPES[lightId])
);

export const CardSubjectRating: FC<ICardSubjectRatingProps> = ({ id }) => {
  const { data, fetching } = useAppSelector((state) => state.lights.totalRates);
  const lights = useAppSelector((state) => state.lights.list.items);

  const rates = useMemo(
    () =>
      ALLOWED_LIGHTS.reduce((prev, lightId) => {
        prev[lightId] = {
          position: data?.[lightId]?.find((row) => row.region.id === id),
          total: {
            [LIGHT_RATES.high]:
              data?.[lightId]?.filter((r) => r.rate === LIGHT_RATES.high)
                .length ?? 0,
            [LIGHT_RATES.middle]:
              data?.[lightId]?.filter((r) => r.rate === LIGHT_RATES.middle)
                .length ?? 0,
            [LIGHT_RATES.low]:
              data?.[lightId]?.filter((r) => r.rate === LIGHT_RATES.low)
                .length ?? 0,
            max: 0,
          },
        };

        prev[lightId].total.max =
          prev[lightId].total[LIGHT_RATES.high] +
          prev[lightId].total[LIGHT_RATES.middle] +
          prev[lightId].total[LIGHT_RATES.low];

        return prev;
      }, {} as { [lightId: string]: { position: ILightTableRow | undefined; total: { max: number; [LIGHT_RATES.high]: number; [LIGHT_RATES.middle]: number; [LIGHT_RATES.low]: number } } }),
    [data, id]
  );

  if (fetching) {
    return (
      <Card className={styles.cardEmpty}>
        <Typography.Title level={3} className={styles.title}>
          Позиция субъекта РФ в рейтингах
        </Typography.Title>
        <div className={styles.cardEmptyContent}>
          <Spin />
        </div>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card className={styles.cardEmpty}>
        <Typography.Title level={3} className={styles.title}>
          Позиция субъекта РФ в рейтингах
        </Typography.Title>
        <div className={styles.cardEmptyContent}>
          <Empty />
        </div>
      </Card>
    );
  }

  return (
    <Card className={styles.card}>
      <Typography.Title level={3} className={styles.title}>
        Позиция субъекта РФ в рейтингах
      </Typography.Title>
      <div className={styles.cardContent}>
        {Object.keys(rates).map((lightId) => {
          return (
            <div key={lightId} className={styles.progressBarWrapper}>
              <ProgressBar
                segments={[
                  {
                    color:
                      rates[lightId].position?.rate === LIGHT_RATES.high
                        ? ProgressColors.green
                        : undefined,
                    value: rates[lightId].total[LIGHT_RATES.high],
                  },
                  {
                    color:
                      rates[lightId].position?.rate === LIGHT_RATES.middle
                        ? ProgressColors.yellow
                        : undefined,
                    value: rates[lightId].total[LIGHT_RATES.middle],
                  },
                  {
                    color:
                      rates[lightId].position?.rate === LIGHT_RATES.low
                        ? ProgressColors.red
                        : undefined,
                    value: rates[lightId].total[LIGHT_RATES.low],
                  },
                ]}
                max={rates[lightId].total.max}
                position={rates[lightId].position?.sn}
              />
              {
                lights?.find((light) => light.id === lightId)?.attributeVals
                  .Наименование
              }
            </div>
          );
        })}
      </div>
    </Card>
  );
};
