import React, { ReactNode } from "react";

import * as styles from "./CardHeader.module.scss";

export enum CardHeaderTypes {
  PlanFactProgressBar,
}

export type CardHeaderProps = { title: ReactNode; big?: boolean } & (
  | {
      right: ReactNode;
      fullSize?: boolean;
    }
  | { helperText: ReactNode }
  | {
      helperText: ReactNode;
      subTitle: string;
    }
);

export const CardHeader: React.FC<CardHeaderProps> = ({
  title,
  big,
  ...props
}) => {
  if ("subTitle" in props && !big) {
    return (
      <div>
        <div className={styles.helperText}>{props.helperText}</div>
        <div className={styles.subTitle}>{props.subTitle}</div>
        <div className={styles.cardHeaderSm}>{title}</div>
      </div>
    );
  }
  if ("helperText" in props) {
    return (
      <div>
        <div className={styles.helperText}>{props.helperText}</div>
        <div className={styles.cardHeader}>{title}</div>
      </div>
    );
  }

  if ("fullSize" in props) {
    return (
      <div>
        <div className={styles.cardHeader}>
          <span>{title}</span>
          <span className={styles.cardHeaderRightFullSize}>{props.right}</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderTitle}>{title}</div>
        {props.right}
      </div>
    </div>
  );
};
