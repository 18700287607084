import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.stimul;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryStimul: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, district, districtRate, total, totals, fetching } =
    useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["54"]
          ? moment(data.curr["54"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["54"]
              ? formatNumber(data.curr["54"].value, undefined, 1)
              : "-"
          }
          description="млн. руб., кассовое исполнение"
        />
        <CardInfo
          value={
            data?.curr["58"]
              ? formatNumber(data.curr["58"].value, undefined, 0)
              : "-"
          }
          description="шт., объекты, по которым заключены контракты"
        />
        <CardInfo
          value={
            data?.curr["61"]
              ? formatNumber(data.curr["61"].value, undefined, 0)
              : "-"
          }
          description="шт., завершено объектов"
        />
        <CardInfo
          value={
            data?.curr["62"]
              ? formatNumber(data.curr["62"].value, undefined, 0)
              : "-"
          }
          description="шт., объекты, по которым ведутся СМР"
        />
      </div>
      <div>
        <div className={clsx(styles.heading, styles.marginTop48)}>
          Процент кассового исполнения
        </div>
        <div className={styles.flex}>
          <div className={styles.width33}>
            <div className={styles.count}>
              {totals?.[LIGHT_ID]?.["55"]
                ? formatNumber(
                    totals[LIGHT_ID]["55"]?.value,
                    totals[LIGHT_ID]["55"].attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>РФ</span>
          </div>
          <div className={styles.width33}>
            <div
              className={clsx(
                styles.count,
                styles[districtRate as keyof typeof styles]
              )}
            >
              {district?.[LIGHT_ID]?.["55"]
                ? formatNumber(
                    district[LIGHT_ID]["55"].value,
                    district[LIGHT_ID]["55"].attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>Федеральный округ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {data?.curr["55"]
                ? formatNumber(
                    data.curr["55"]?.value,
                    data.curr["55"].attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>Субъект РФ</span>
          </div>
        </div>

        <div className={clsx(styles.heading, styles.marginTop48)}>
          Доля объектов, по которым заключены контракты
        </div>
        <div className={styles.flex}>
          <div className={styles.width33}>
            <div className={styles.count}>
              {totals?.[LIGHT_ID]?.["59"]
                ? formatNumber(
                    totals[LIGHT_ID]["59"]?.value,
                    totals[LIGHT_ID]["59"].attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>РФ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {district?.[LIGHT_ID]?.["59"]
                ? formatNumber(
                    district[LIGHT_ID]["59"].value,
                    district[LIGHT_ID]["59"].attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>Федеральный округ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {data?.curr["59"]
                ? formatNumber(
                    data.curr["59"]?.value,
                    data.curr["59"].attributeVals.UNIT,
                    1
                  )
                : "-"}
            </div>
            <span className={styles.info}>Субъект РФ</span>
          </div>
        </div>

        <div className={clsx(styles.heading, styles.marginTop48)}>
          Количество объектов, шт.
        </div>
        <div className={styles.flex}>
          <div className={styles.width33}>
            <div className={styles.count}>
              {totals?.[LIGHT_ID]?.["57"]
                ? formatNumber(totals[LIGHT_ID]["57"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>РФ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {district?.[LIGHT_ID]?.["57"]
                ? formatNumber(district[LIGHT_ID]["57"].value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>Федеральный округ</span>
          </div>
          <div className={styles.width33}>
            <div
              className={clsx(
                styles.count,
                styles[total?.rate as keyof typeof styles]
              )}
            >
              {data?.curr["57"]
                ? formatNumber(data.curr["57"]?.value, undefined, 0)
                : "-"}
            </div>
            <span className={styles.info}>Субъект РФ</span>
          </div>
        </div>
      </div>
    </>
  );
};
