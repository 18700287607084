import { clamp } from "./number";

export const numberCompare = (a: number, b: number) => clamp(a - b, -1, 1);
export const stringCompare = (a: string, b: string) => a.localeCompare(b);

export const valueCompare = <T extends unknown>(left: T, right: T) =>
  typeof left === "number" && typeof right === "number"
    ? numberCompare(left, right)
    : stringCompare(`${left}`, `{right}`);

export const createByFieldSorter =
  <O extends Object>(key: keyof O) =>
  (a: O, b: O) =>
    valueCompare(a[key], b[key]);
