import { useMemo } from "react";

import { useAppSelector } from "../../../redux/hooks";
import { LIGHT_RATE_FUNCS } from "../../../redux/slices/lights/api/rate/rate";
import { LIGHTS_MAP_TYPES } from "../../../redux/slices/lights/api/scheme";
import { useRegions } from "../../../redux/slices/lights/hooks/useRegions";
import { getRegion } from "../../../redux/slices/lights/utils";
import { REGION_COUNTRY_CODE } from "../../../redux/slices/regions/api/scheme";

export const useCardSubjectSummary = (regionCode: string, LIGHT_ID: string) => {
  const { getDistrictByRegion, regions, districts } = useRegions();
  const districtCode = getDistrictByRegion(regionCode);
  const { data: totals, fetching: totalFetching } = useAppSelector(
    (state) => state.lights.total
  );
  const { data: districtsData, fetching: districtsFetching } = useAppSelector(
    (state) => state.lights.districts
  );
  const { data: totalRates, fetching: totalRatesFetching } = useAppSelector(
    (state) => state.lights.totalRates
  );
  const { data, fetching: dynamicsRegionFetching } = useAppSelector(
    (state) => state.lights.dynamicsRegion
  );

  const fetching =
    totalFetching ||
    totalRatesFetching ||
    districtsFetching ||
    dynamicsRegionFetching;

  const total = useMemo(
    () => totalRates?.[LIGHT_ID]?.find((row) => row.region.code === regionCode),
    [LIGHT_ID, regionCode, totalRates]
  );

  const district = districtCode ? districtsData?.[districtCode] : undefined;

  const districtRate = useMemo(() => {
    const rateFunc =
      LIGHT_RATE_FUNCS[
        LIGHTS_MAP_TYPES[LIGHT_ID as keyof typeof LIGHTS_MAP_TYPES]
      ];

    if (
      !rateFunc ||
      !district?.[LIGHT_ID] ||
      !totals?.[LIGHT_ID] ||
      !totalRates?.[LIGHT_ID]
    ) {
      return undefined;
    }

    const districtRow = {
      ...district[LIGHT_ID],
      region: getRegion(districtCode!, districts),
    };

    const countryRow = {
      ...totals?.[LIGHT_ID],
      region: getRegion(REGION_COUNTRY_CODE, regions),
    };

    return rateFunc(districtRow, [countryRow, ...totalRates?.[LIGHT_ID]]);
  }, [
    LIGHT_ID,
    district,
    districtCode,
    districts,
    regions,
    totalRates,
    totals,
  ]);

  return {
    total,
    totals,
    totalRates,
    district,
    districtsData,
    districtCode,
    districtRate,
    data,
    fetching,
  };
};
