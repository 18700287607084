import { createElement, FC } from "react";

import { LIGHTS_MAP } from "../../redux/slices/lights/api/scheme";
import { CardBaikalMetrics } from "./CardBaikalMetrics";
import { CardCityMetrics } from "./CardCityMetrics";
import { CardHousingMetrics } from "./CardHousingMetrics";
import { CardMortgageMetrics } from "./CardMortgageMetrics";
import { CardProjectMetrics } from "./CardProjectsMetrics";
import { CardRecoveryVolgaMetrics } from "./CardRecoveryVolgaMetrics";
import { CardResettlementMetrics } from "./CardResettlementMetrics";
import { CardRoadsMetrics } from "./CardRoadsMetrics";
import { CardSharedConstructionMetrics } from "./CardSharedConstructionMetrics";
import { CardStimulMetrics } from "./CardStimulMetrics";
import { CardWaterMetrics } from "./CardWaterMetrics";

const CARD_METRICS_COMPONENT: { [id: string]: any } = {
  [LIGHTS_MAP.housing]: CardHousingMetrics,
  [LIGHTS_MAP.roads]: CardRoadsMetrics,
  [LIGHTS_MAP.roadsDead]: CardRoadsMetrics,
  [LIGHTS_MAP.roadsRegional]: CardRoadsMetrics,
  [LIGHTS_MAP.roadsInfrastructure]: CardRoadsMetrics,
  [LIGHTS_MAP.resettlement]: CardResettlementMetrics,
  [LIGHTS_MAP.stimul]: CardStimulMetrics,
  [LIGHTS_MAP.project]: CardProjectMetrics,
  [LIGHTS_MAP.city]: CardCityMetrics,
  [LIGHTS_MAP.water]: CardWaterMetrics,
  [LIGHTS_MAP.mortgage]: CardMortgageMetrics,
  [LIGHTS_MAP.sharedConstruction]: CardSharedConstructionMetrics,
  [LIGHTS_MAP.recoveryVolga]: CardRecoveryVolgaMetrics,
  [LIGHTS_MAP.baikal]: CardBaikalMetrics,
};

export interface ICardMetrics {
  id: string;
}

export const CardMetrics: FC<ICardMetrics> = ({ id }) => {
  return createElement(CARD_METRICS_COMPONENT[id], { id });
};
