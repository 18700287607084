import React from "react";

import { formatNumber } from "../../lib/number";
import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import { calcPlanFactSegments } from "../../redux/slices/lights/utils";
import {
  ArrowDirection,
  ProgressBar,
  ProgressColors,
} from "../ProgressBar/ProgressBar";
import { TextValue } from "../TextValue/TextValue";
import * as styles from "./PlanFactProgressBar.module.scss";

export type PlanFactProgressBarProps = {
  plan: number;
  fact: number;
  prev?: number;
  dynamics?: number;
  fractionDigit?: number;
  unit?: string;
  rate?: LIGHT_RATES;
  prevLabel?: string;
  nextLabel?: string;
  hideLabels?: boolean;
};

export const PlanFactProgressBar: React.FC<PlanFactProgressBarProps> = ({
  plan,
  fact,
  prev,
  dynamics,
  fractionDigit,
  unit,
  rate,
  prevLabel = "Факт",
  nextLabel = "План",
  hideLabels,
}) => {
  const [minValue, maxValue, currentValue] = calcPlanFactSegments(
    plan,
    fact > plan ? plan : fact, // В диаграмме "План-Факт не показываем перевыполнение плана"
    prev
  );

  const headerValue = prev ? fact : dynamics;
  const headerUnit = prev ? unit : headerValue ? "%" : "";
  const extraValue = prev ? dynamics : undefined;
  const fDigits = fractionDigit ?? (prev ? 1 : 0);

  return (
    <div>
      <TextValue
        valueSize="big"
        value={formatNumber(headerValue, headerUnit, fDigits)}
        extraValue={extraValue}
        extraRate={rate}
        fractionDigits={1}
      />
      <div className={styles.progressBar}>
        <ProgressBar
          segments={[
            { color: ProgressColors.blue, value: minValue },
            {
              color:
                rate === LIGHT_RATES.high
                  ? ProgressColors.green
                  : ProgressColors.red,
              value: Math.abs(currentValue - minValue),
              arrow: true,
              arrowDirection:
                rate === LIGHT_RATES.high
                  ? ArrowDirection.right
                  : ArrowDirection.left,
            },
          ]}
          max={maxValue}
        />
      </div>
      {!hideLabels && (
        <div className={styles.footer}>
          <div>
            {prev ? (
              <>
                {prevLabel}:{" "}
                <span className={styles.value}>
                  {formatNumber(prev ?? fact, unit, fDigits)}
                </span>
              </>
            ) : (
              <>
                Факт:{" "}
                <span className={styles.value}>
                  {formatNumber(prev ?? fact, unit, fDigits)}
                </span>
              </>
            )}
          </div>
          <div>
            {nextLabel}:{" "}
            <span className={styles.value}>
              {formatNumber(plan, unit, fDigits)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
