import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getModel,
  ILightData,
  IPeriodAttributeVals,
} from "../../../core/scheme/olap";
import { REGION_DISTRICT_CODES } from "../regions/api/scheme";
import { getLightsTotals } from "./api/client";
import { prepareAttributes } from "./utils";

export interface ILightDistrictsData {
  [district: string]: ILightData;
}

export interface ILightsDistrictsState {
  data?: ILightDistrictsData;
  fetching: boolean;
}

export const initialState: ILightsDistrictsState = {
  data: undefined,
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk(
    "lights/districts",
    async ({ date, ids }: { date: string; ids: string[] }) =>
      await getLightsTotals({
        ids,
        periodIds: [date],
        regionIds: REGION_DISTRICT_CODES,
      })
  ),
};

const slice = createSlice({
  name: "lightsDistricts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const model = getModel();

        state.data = (action.payload || []).reduce((prev, row) => {
          const districtCode = row.code;
          const date = (row.attributeVals as IPeriodAttributeVals).REPORT_DATE;
          const item = row.dims[model.dimId];
          const lightId = item.parentId[0];
          const fieldId = item.code;

          if (!prev[districtCode]) {
            prev[districtCode] = {};
          }

          if (!prev[districtCode][lightId]) {
            prev[districtCode][lightId] = {};
          }

          prev[districtCode][lightId][fieldId] = {
            id: item.id,
            code: fieldId,
            date,
            level: item.level,
            value: item.indicatorVals[model.sumIndicatorId]?.sum,
            attributeVals: prepareAttributes(
              item.attributeVals
            ) as ILightData[0][0]["attributeVals"], // TODO
          };
          return prev;
        }, {} as ILightDistrictsData);

        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
