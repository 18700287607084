import { Spin } from "antd";
import { useCallback, useMemo } from "react";

import { Card } from "../../components/Card/Card";
import { useRouter } from "../../hooks/useRouter";
import { useAppSelector } from "../../redux/hooks";
import {
  ADDITIONAL_LIGHTS_MAP,
  EXTRA_LIGHTS_MAP,
} from "../../redux/slices/lights/hooks/useLight";
import { ILightListItem } from "../../redux/slices/lights/listSlice";
import * as styles from "./Home.module.scss";

export const Home = () => {
  const { navigate } = useRouter();
  const { items, fetching: lightsFetching } = useAppSelector(
    (state) => state.lights.list
  );
  const regionsFetching = useAppSelector(
    (state) =>
      state.regions.districts.fetching || state.regions.regions.fetching
  );

  const fetching = lightsFetching || regionsFetching;

  const lights = useMemo(
    () =>
      items.filter(
        (item) =>
          !EXTRA_LIGHTS_MAP.find((list) => list.includes(item.id)) &&
          !ADDITIONAL_LIGHTS_MAP.find((list) =>
            list.slice(1).some((list) => list.light === item.id)
          )
      ),
    [items]
  );

  const onClick = useCallback(
    (item: ILightListItem) => () => {
      navigate(`/projects/${item.id}`);
    },
    [navigate]
  );

  return (
    <div className={styles.cards}>
      {fetching ? (
        <div className={styles.spin}>
          <Spin />
        </div>
      ) : (
        lights.map((item) => (
          <div key={item.id} className={styles.card} onClick={onClick(item)}>
            <Card id={item.id} big={false} />
          </div>
        ))
      )}
    </div>
  );
};
