import { Typography } from "antd";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";

import { Card } from "../../components/Card/Card";
import { DynamicsRating } from "../../components/Dynamics/components/rating/DynamicsRating";
import { DynamicsTable } from "../../components/Dynamics/components/table/DynamicsTable";
import * as styles from "../../components/Dynamics/Dynamics.module.scss";
import { useDate } from "../../hooks/useDate";
import { useAppDispatch } from "../../redux/hooks";
import actions from "../../redux/slices/lights/actions";
import { DATE_DISPLAY_FORMAT } from "../../redux/slices/lights/api/scheme";
import {
  EXTRA_LIGHTS_MAP,
  STICK_LIGHTS_MAP,
  useLight,
} from "../../redux/slices/lights/hooks/useLight";

export const Dynamics: React.FC = () => {
  const dispatch = useAppDispatch();
  const { date } = useDate();
  const { id = "" } = useParams<string>();
  const { light } = useLight(id);
  const { additionalLights } = useLight(id);
  const { total } = useLight(id);

  useEffect(() => {
    const lightId = additionalLights?.mainLight ?? id;
    const ids = [lightId, ...(STICK_LIGHTS_MAP[lightId] ?? [])];
    const additionalIds =
      id !== lightId && additionalLights?.selected.id
        ? [
            additionalLights.selected.id,
            ...(STICK_LIGHTS_MAP[additionalLights.selected.id] ?? []),
          ]
        : [];

    dispatch<any>(actions.dynamics.get({ ids, additionalIds, date }));
  }, [
    additionalLights?.mainLight,
    additionalLights?.selected,
    date,
    dispatch,
    id,
  ]);

  const dinamycsCards = useMemo(() => {
    if (!light) {
      return undefined;
    }

    if (!total) {
      return undefined;
    }

    const firstVal = Object.keys(total)[0];

    return !EXTRA_LIGHTS_MAP.find((list) => list.includes(light.id)) ? (
      <div className={styles.cardRow}>
        <Card id={light.id} big />
        <DynamicsRating id={light.id} />
      </div>
    ) : (
      total && (
        <div className={styles.helperText}>
          Данные на {moment(total[firstVal]?.date).format(DATE_DISPLAY_FORMAT)}
        </div>
      )
    );
  }, [light, total]);

  return light ? (
    <div>
      <div className={styles.cardTop}>
        <Typography.Title level={2}>
          {light.attributeVals["Наименование"]}
        </Typography.Title>
        {dinamycsCards}
      </div>
      <DynamicsTable id={light.id} />
    </div>
  ) : null;
};
