import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  DATA_PERIOD_2020W53,
  getModel,
  ILightRow,
  IPeriodAttributeVals,
} from "../../../core/scheme/olap";
import { getLightDynamics } from "./api/client";
import { prepareAttributes } from "./utils";

export interface ILightsDynamicsRegionData {
  prev: ILightRow;
  curr: ILightRow;
}

export interface ILightsDynamicsRegionState {
  data?: ILightsDynamicsRegionData;
  fetching: boolean;
}

export const initialState: ILightsDynamicsRegionState = {
  data: undefined,
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk(
    "lights/dynamics/region",
    async ({ id, date }: { id: string; date: string }) => [
      await getLightDynamics({
        regionIds: [id],
        regionLevels: [0, 2],
        periodIds: [date],
      }),
      await getLightDynamics({
        regionIds: [id],
        regionLevels: [0, 2],
        periodIds: [DATA_PERIOD_2020W53], // TODO: высчитывать прошлый период динамически
        actualData: false,
      }),
    ]
  ),
};

const slice = createSlice({
  name: "lightsDynamicsRegion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const model = getModel();
        const payload = [
          ...(action.payload[0] || []),
          ...(action.payload[1] || []),
        ];

        state.data = payload.reduce(
          (prev, item) => {
            const key =
              item.dims[model.dataPeriodId].code === DATA_PERIOD_2020W53
                ? "prev"
                : "curr";
            const date = (
              item.dims[model.dataPeriodId]
                .attributeVals as IPeriodAttributeVals
            ).REPORT_DATE;
            const code = item.dims[model.dimId].code;

            if (!prev[key]) {
              prev[key] = {};
            }

            prev[key][code] = {
              id: item.dims[model.dimId].id,
              code: item.dims[model.dimId].code,
              date,
              district: item.parentId[0],
              level: item.dims[model.dimId].level,
              value:
                item.dims[model.dimId].indicatorVals[model.sumIndicatorId]?.sum,
              attributeVals: prepareAttributes(
                item.dims[model.dimId].attributeVals
              ),
            };

            return prev;
          },
          { prev: {}, curr: {} } as ILightsDynamicsRegionData
        );

        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
