import { actions as districts } from "./districtsSlice";
import { actions as regions } from "./regionsSlice";

export interface IRegionsActions {
  districts: typeof districts;
  regions: typeof regions;
}

const actions: IRegionsActions = {
  districts,
  regions,
};

export default actions;
