import { Spin } from "antd";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.baikal;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryBaikal: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["230"]
          ? moment(data.curr["230"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["228"]
              ? formatNumber(data.curr["228"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., утвержденные БА (ЛБО)"
        />
        <CardInfo
          value={
            data?.curr["229"]
              ? formatNumber(data.curr["229"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., кассовое исполнение"
        />
        <CardInfo
          value={
            data?.curr["230"]
              ? formatNumber(data.curr["230"].value, undefined, 1)
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
          percent
        />
        <CardInfo
          value={
            data?.curr["249"]
              ? formatNumber(data.curr["249"].value, undefined, 1)
              : "-"
          }
          description="% законтрактованности"
          percent
        />
      </div>
    </>
  );
};
