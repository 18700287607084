import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.water;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryWater: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, district, districtRate, total, totals, fetching } =
    useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["82"]
          ? moment(data.curr["82"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["77"]
              ? formatNumber(data.curr["77"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., утвержденные БА (ЛБО)"
        />
        <CardInfo
          value={
            data?.curr["78"]
              ? formatNumber(data.curr["78"].value, undefined, 1)
              : "-"
          }
          description="тыс. руб., кассовое исполнение"
        />
        <CardInfo
          value={
            data?.curr["79"]
              ? formatNumber(data.curr["79"].value, undefined, 1)
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
          percent
        />
        <CardInfo
          value={
            data?.curr["85"]
              ? formatNumber(data.curr["85"].value, undefined, 1)
              : "-"
          }
          description="% законтрактованности"
          percent
        />
      </div>
      <div>
        <div className={styles.subHeader}>
          <div className={styles.flex}>
            <div className={clsx(styles.width50, styles.heading)}>
              Кассовое исполнение,{" "}
              {totals?.[LIGHT_ID]?.["79"]?.attributeVals.UNIT}
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {totals?.[LIGHT_ID]?.["79"]
                      ? formatNumber(totals[LIGHT_ID]["79"]?.value, "%", 1)
                      : "-"}
                  </div>
                  <span className={styles.info}>РФ</span>
                </div>
                <div className={styles.width50}>
                  <div
                    className={clsx(
                      styles.count,
                      styles[districtRate as keyof typeof styles]
                    )}
                  >
                    {district?.[LIGHT_ID]?.["79"]
                      ? formatNumber(
                          district[LIGHT_ID]["79"].value,
                          district[LIGHT_ID]["79"]?.attributeVals.UNIT,
                          1
                        )
                      : "-"}
                  </div>
                  <span className={styles.info}>Федеральный округ</span>
                </div>
              </div>
            </div>

            <div className={clsx(styles.width50, styles.heading)}>
              Законтрактованность,{" "}
              {totals?.[LIGHT_ID]?.["85"]?.attributeVals.UNIT}
              <div className={styles.flex}>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {totals?.[LIGHT_ID]?.["85"]
                      ? formatNumber(totals[LIGHT_ID]["85"]?.value, "%", 1)
                      : "-"}
                  </div>
                  <span className={styles.info}>РФ</span>
                </div>
                <div className={styles.width50}>
                  <div className={styles.count}>
                    {district?.[LIGHT_ID]?.["85"]
                      ? formatNumber(district[LIGHT_ID]["85"]?.value, "%", 1)
                      : "-"}
                  </div>
                  <span className={styles.info}>Федеральный округ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
