import { Button, Form, Input } from "antd";
import { useCallback } from "react";

import { useAppDispatch } from "../../redux/hooks";
import { actions } from "../../redux/slices/auth/tokenSlice";

export const Login = () => {
  const dispatch = useAppDispatch();

  const onFinish = useCallback(
    (values: { username: string; password: string }) => {
      dispatch(actions.getToken(values));
    },
    [dispatch]
  );

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item
        label="Логин"
        name="username"
        rules={[{ required: true, message: "Введите логин" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Пароль"
        name="password"
        rules={[{ required: true, message: "Введите пароль" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Авторизоваться
        </Button>
      </Form.Item>
    </Form>
  );
};
