import { Card } from "antd";
import moment from "moment";
import React, { useMemo } from "react";

import { formatNumber } from "../../lib/number";
import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_TYPES,
} from "../../redux/slices/lights/api/scheme";
import {
  LIGHT_FACT,
  LIGHT_FIELD_DYNAMICS,
  LIGHT_PLAN,
  useLight,
} from "../../redux/slices/lights/hooks/useLight";
import { CardHeader } from "../CardHeader/CardHeader";
import { CardSubStatsContent } from "../CardSubStatsContent/CardSubStatsContent";
import { Empty } from "../Empty";
import { PlanFactProgressBar } from "../PlanFactProgressBar/PlanFactProgressBar";

type PropsType = {
  id: string;
  big: boolean;
};

export const CardSharedConstructionMetrics: React.FC<PropsType> = ({
  id,
  big,
}) => {
  const { total } = useLight(id);

  const dynamics =
    total?.[LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.sharedConstruction]]?.value;

  const rate = useMemo(() => {
    if (dynamics != null) {
      return dynamics > 0
        ? LIGHT_RATES.low
        : dynamics < 0
        ? LIGHT_RATES.high
        : undefined;
    }
  }, [dynamics]);

  return (
    <Card
      bordered={false}
      title={
        <CardHeader
          big={big}
          title="Проблемные дома (прирост)"
          subTitle="Долевое строительство"
          helperText={
            total
              ? `Данные на ${moment(total["123"]?.date).format(
                  DATE_DISPLAY_FORMAT
                )}`
              : ""
          }
        />
      }
    >
      {total ? (
        <>
          <PlanFactProgressBar
            plan={LIGHT_PLAN[LIGHTS_TYPES.sharedConstruction](total)?.value}
            fact={LIGHT_FACT[LIGHTS_TYPES.sharedConstruction](total)?.value}
            prev={total["123"]?.value}
            dynamics={dynamics}
            fractionDigit={0}
            unit={
              LIGHT_PLAN[LIGHTS_TYPES.sharedConstruction](total)?.attributeVals
                .UNIT
            }
            rate={rate}
            prevLabel="2020"
            nextLabel="Всего объектов ЖС"
          />
          <CardSubStatsContent
            title="Площадь жилищного строительства"
            titleRight={` · ${total["118"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["118"]?.value, undefined, 0),
                valueSize: big ? "big" : "small",
                extraValue: total["128"]?.value,
                extraRate:
                  total["128"]?.value > 0 ? LIGHT_RATES.low : LIGHT_RATES.high,
                footerText: "Проблемных",
              },
              {
                value: formatNumber(total["116"]?.value, undefined, 0),
                valueSize: big ? "big" : "small",
                footerText: "Всего",
              },
            ]}
          />
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
