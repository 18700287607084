import clsx from "clsx";
import React from "react";

import { ProgressBar, ProgressColors } from "../ProgressBar/ProgressBar";
import * as styles from "./TotalRateProgressBar.module.scss";

export type LevelAchievProgressBarPropsTypes = {
  redCircle: number;
  orangeCircle: number;
  greenCircle: number;
  redText: string;
  orangeText: string;
  greenText: string;
};

export const TotalRateProgressBar: React.FC<LevelAchievProgressBarPropsTypes> =
  ({
    redCircle,
    orangeCircle,
    greenCircle,
    redText,
    orangeText,
    greenText,
  }) => {
    const subjectCount = redCircle + orangeCircle + greenCircle;
    return (
      <div className={styles.view}>
        <h4 className={styles.view_title}>
          Уровень достижений субъектов РФ
          {subjectCount < 85 ? (
            <span className={styles.subjectCount}>({subjectCount})</span>
          ) : null}
        </h4>
        <div className={styles.circles}>
          <div>
            <div className={styles.circle}>
              <p className={styles.circle__num}>{redCircle}</p>
            </div>
            <p className={styles.circle__description}>{redText}</p>
          </div>
          <div>
            <div className={clsx(styles.circle, styles.circle_orange)}>
              <p
                className={clsx(styles.circle__num, styles.circle__num_orange)}
              >
                {orangeCircle}
              </p>
            </div>
            <p className={styles.circle__description}>{orangeText}</p>
          </div>
          <div>
            <div className={clsx(styles.circle, styles.circle_green)}>
              <p className={clsx(styles.circle__num, styles.circle__num_green)}>
                {greenCircle}
              </p>
            </div>
            <p className={styles.circle__description}>{greenText}</p>
          </div>
        </div>
        <ProgressBar
          segments={[
            { color: ProgressColors.red, value: redCircle },
            { color: ProgressColors.yellow, value: orangeCircle },
            { color: ProgressColors.green, value: greenCircle },
          ]}
          max={redCircle + orangeCircle + greenCircle}
        />
      </div>
    );
  };
