import { Card, Spin, Typography } from "antd";
import moment from "moment";
import React, { FC } from "react";

import { useAppSelector } from "../../redux/hooks";
import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import { DATE_DEFAULT_FORMAT } from "../../redux/slices/lights/api/scheme";
import { calcPrevByDynamic } from "../../redux/slices/lights/utils";
import { Empty } from "../Empty";
import { PlanFactProgressBar } from "../PlanFactProgressBar/PlanFactProgressBar";
import * as styles from "./CardSubjectBudget.module.scss";

export const CardSubjectBudget: FC = () => {
  const { data, fetching } = useAppSelector(
    (state) => state.lights.dynamicsRegion
  );

  if (fetching) {
    return (
      <Card className={styles.cardEmpty}>
        <Typography.Title level={3} className={styles.title}>
          Бюджет
        </Typography.Title>
        <div className={styles.cardEmptyContent}>
          <Spin />
        </div>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card className={styles.cardEmpty}>
        <Typography.Title level={3} className={styles.title}>
          Бюджет
        </Typography.Title>
        <div className={styles.cardEmptyContent}>
          <Empty />
        </div>
      </Card>
    );
  }

  return (
    <Card className={styles.card}>
      <Typography.Title level={3} className={styles.title}>
        Бюджет
      </Typography.Title>
      <Typography.Title level={5}>
        Сбалансированность, млрд. руб.
      </Typography.Title>
      <div className={styles.titleDateWrapper}>
        <div className={styles.titleDate}>
          {moment(data.prev["144"]?.date).format(DATE_DEFAULT_FORMAT)}
        </div>
        <div className={styles.titleDate}>
          {moment(data.curr["144"]?.date).format(DATE_DEFAULT_FORMAT)}
        </div>
      </div>

      <div className={styles.subTitle}>Доходы</div>
      <div className={styles.progressBarWrapper}>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={
              data.prev["146"]?.value
                ? data.prev["144"]?.value
                : data.prev["145"]?.value
            }
            fact={data.prev["145"]?.value}
            prev={
              data.prev["146"]?.value && data.prev["146"]?.value > 0
                ? calcPrevByDynamic(
                    data.prev["145"]?.value,
                    data.prev["146"]?.value
                  )
                : data.prev["145"]?.value
            }
            dynamics={data.prev["146"]?.value}
            rate={
              data.prev["146"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={data.curr["144"]?.value}
            fact={data.curr["145"]?.value}
            prev={calcPrevByDynamic(
              data.curr["145"]?.value,
              data.curr["146"]?.value
            )}
            dynamics={data.curr["146"]?.value}
            rate={
              data.curr["146"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
      </div>

      <div className={styles.subTitle}>Расходы</div>
      <div className={styles.progressBarWrapper}>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={
              data.prev["155"]?.value
                ? data.prev["153"]?.value
                : data.prev["154"]?.value
            }
            fact={data.prev["154"]?.value}
            prev={
              data.prev["155"]?.value && data.prev["155"]?.value > 0
                ? calcPrevByDynamic(
                    data.prev["154"]?.value,
                    data.prev["155"]?.value
                  )
                : data.prev["154"]?.value
            }
            dynamics={data.prev["155"]?.value}
            rate={
              data.prev["155"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={data.curr["153"]?.value}
            fact={data.curr["154"]?.value}
            prev={calcPrevByDynamic(
              data.curr["154"]?.value,
              data.curr["155"]?.value
            )}
            dynamics={data.curr["155"]?.value}
            rate={
              data.curr["155"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
      </div>

      <div className={styles.subTitle}>Дефицит/Профицит</div>
      <div className={styles.progressBarWrapper}>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={
              data.prev["158"]?.value
                ? data.prev["156"]?.value
                : data.prev["157"]?.value
            }
            fact={data.prev["157"]?.value}
            prev={
              data.prev["158"]?.value && data.prev["158"]?.value > 0
                ? calcPrevByDynamic(
                    data.prev["157"]?.value,
                    data.prev["158"]?.value
                  )
                : data.prev["157"]?.value
            }
            dynamics={data.prev["158"]?.value}
            rate={
              data.prev["158"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={
              data.curr["158"]?.value
                ? data.curr["156"]?.value
                : data.curr["157"]?.value
            }
            fact={data.curr["157"]?.value}
            prev={
              data.curr["158"]?.value && data.curr["158"]?.value > 0
                ? calcPrevByDynamic(
                    data.curr["157"]?.value,
                    data.curr["158"]?.value
                  )
                : data.curr["157"]?.value
            }
            dynamics={data.curr["158"]?.value}
            rate={
              data.curr["158"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
      </div>

      <div className={styles.subTitle}>Госдолг</div>
      <div className={styles.progressBarWrapper}>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={data.prev["159"]?.value}
            fact={data.prev["160"]?.value}
            prev={calcPrevByDynamic(
              data.prev["160"]?.value,
              data.prev["161"]?.value
            )}
            dynamics={data.prev["161"]?.value}
            rate={
              data.prev["161"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
        <div className={styles.progressBar}>
          <PlanFactProgressBar
            plan={data.curr["159"]?.value}
            fact={data.curr["160"]?.value}
            prev={calcPrevByDynamic(
              data.curr["160"]?.value,
              data.curr["161"]?.value
            )}
            dynamics={data.curr["161"]?.value}
            rate={
              data.curr["161"]?.value > 0 ? LIGHT_RATES.high : LIGHT_RATES.low
            }
            hideLabels
          />
        </div>
      </div>
    </Card>
  );
};
