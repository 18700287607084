import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.mortgage;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryMortgage: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, district, districtRate, total, totals, fetching } =
    useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["108"]
          ? moment(data.curr["108"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["108"]
              ? formatNumber(data.curr["108"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["108"]?.attributeVals.UNIT ?? ""
          }, портфель ипотечных кредитов`}
        />
        <CardInfo
          value={
            data?.curr["106"]
              ? formatNumber(data.curr["106"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["106"]?.attributeVals.UNIT ?? ""
          }, выдано ипотечных кредитов с начала года`}
        />
        <CardInfo
          value={
            data?.curr["107"]
              ? formatNumber(
                  data.curr["107"].value,
                  data.curr["107"].attributeVals.UNIT,
                  0
                )
              : "-"
          }
          description="% к прошлому году"
          subRate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["110"]
              ? formatNumber(data.curr["110"].value, "%", 2)
              : "-"
          }
          description="средняя ставка по ипотечным кредитам выданным с начала года"
        />
      </div>
      <div>
        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Портфель ипотечных кредитов,{" "}
            {totals?.[LIGHT_ID]?.["108"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["108"]
                    ? formatNumber(totals[LIGHT_ID]["108"]?.value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {district?.[LIGHT_ID]?.["108"]
                    ? formatNumber(
                        district[LIGHT_ID]["108"].value,
                        undefined,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Выдано ипотечных кредитов с начала года,{" "}
            {totals?.[LIGHT_ID]?.["106"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["106"]
                    ? formatNumber(totals[LIGHT_ID]["106"]?.value, undefined, 1)
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {district?.[LIGHT_ID]?.["106"]
                    ? formatNumber(
                        district[LIGHT_ID]["106"].value,
                        undefined,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Процент к прошлому году
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["107"]
                    ? formatNumber(
                        totals[LIGHT_ID]["107"]?.value,
                        totals[LIGHT_ID]["107"].attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div
                  className={clsx(
                    styles.count,
                    styles[districtRate as keyof typeof styles]
                  )}
                >
                  {district?.[LIGHT_ID]?.["107"]
                    ? formatNumber(
                        district[LIGHT_ID]["107"].value,
                        district[LIGHT_ID]["107"].attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Средняя ставка по ипотечным кредитам выданным с начала года
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["110"]
                    ? formatNumber(
                        totals[LIGHT_ID]["110"]?.value,
                        totals[LIGHT_ID]["110"].attributeVals.UNIT,
                        2
                      )
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {district?.[LIGHT_ID]?.["110"]
                    ? formatNumber(
                        district[LIGHT_ID]["110"].value,
                        district[LIGHT_ID]["110"].attributeVals.UNIT,
                        2
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
