import { Card } from "antd";
import moment from "moment";
import React, { useMemo } from "react";

import { formatNumber } from "../../lib/number";
import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_TYPES,
} from "../../redux/slices/lights/api/scheme";
import {
  LIGHT_FACT,
  LIGHT_FIELD_DYNAMICS,
  LIGHT_PLAN,
  useLight,
} from "../../redux/slices/lights/hooks/useLight";
import { CardHeader } from "../CardHeader/CardHeader";
import { CardSubStatsContent } from "../CardSubStatsContent/CardSubStatsContent";
import { Empty } from "../Empty";
import { PlanFactProgressBar } from "../PlanFactProgressBar/PlanFactProgressBar";

type PropsType = {
  id: string;
  big: boolean;
};

export const CardHousingMetrics: React.FC<PropsType> = ({ id, big }) => {
  const { total } = useLight(id);

  const dynamics = total?.[LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.housing]]?.value;

  const rate = useMemo(() => {
    if (!dynamics) {
      return undefined;
    }

    return dynamics > 0
      ? LIGHT_RATES.high
      : dynamics < 0
      ? LIGHT_RATES.low
      : undefined;
  }, [dynamics]);

  return (
    <Card
      bordered={false}
      title={
        <CardHeader
          big={big}
          title="Ввод жилья в эксплуатацию"
          subTitle="Динамика ввода жилья"
          helperText={
            total
              ? `Данные на ${moment(total["4"]?.date).format(
                  DATE_DISPLAY_FORMAT
                )}`
              : ""
          }
        />
      }
    >
      {total ? (
        <>
          <PlanFactProgressBar
            plan={LIGHT_PLAN[LIGHTS_TYPES.housing](total)?.value}
            fact={LIGHT_FACT[LIGHTS_TYPES.housing](total)?.value}
            prev={total["4"]?.value}
            dynamics={dynamics}
            unit={LIGHT_PLAN[LIGHTS_TYPES.housing](total)?.attributeVals.UNIT}
            rate={rate}
            prevLabel="2020"
          />
          <CardSubStatsContent
            title="Объем ввода на человека"
            titleRight={` · ${total["6"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["6"]?.value, undefined, 3),
                valueSize: big ? "big" : "small",
                footerText: "Факт",
              },
              {
                value: formatNumber(total["9"]?.value, undefined, 3),
                valueSize: big ? "big" : "small",
                footerText: "План",
              },
            ]}
          />
          <CardSubStatsContent
            title="Введено в эксплуатацию"
            titleRight={` · ${total["13"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["13"]?.value, undefined, 0),
                extraValue: total["14"]?.value,
                extraRate: LIGHT_RATES.high,
                valueSize: big ? "big" : "small",
                footerText: "МКД",
              },
              {
                value: formatNumber(total["11"]?.value, undefined, 0),
                extraValue: total["12"]?.value,
                extraRate: LIGHT_RATES.high,
                valueSize: big ? "big" : "small",
                footerText: "ИЖС",
              },
            ]}
          />
          <CardSubStatsContent
            title="Действующие разрешения на строительство"
            titleRight={` · ${total["18"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["18"]?.value, undefined, 0),
                valueSize: big ? "big" : "small",
                footerText: "Всего",
              },
              {
                value: formatNumber(total["16"]?.value, undefined, 0),
                valueSize: big ? "big" : "small",
                footerText: "ГП Р/с",
              },
            ]}
          />
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
