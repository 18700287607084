import { FC } from "react";

import { LIGHTS_TYPES } from "../../../../redux/slices/lights/api/scheme";
import { useLight } from "../../../../redux/slices/lights/hooks/useLight";
import { DynamicsRatingBase } from "./DynamicsRatingBase";

export interface IDynamicsRatingProps {
  id: string;
}

export const DynamicsRating: FC<IDynamicsRatingProps> = ({ id }) => {
  const { light } = useLight(id);

  if (!light) {
    return null;
  }

  switch (light?.type) {
    case LIGHTS_TYPES.housing:
      return (
        <DynamicsRatingBase
          id={light.id}
          fractionDigits={1}
          fieldPrevPeriod="4"
          valueText="Объем ввода"
        />
      );
    case LIGHTS_TYPES.mortgage:
      return (
        <DynamicsRatingBase
          id={light.id}
          fractionDigits={1}
          valueText="Прирост выданных ИК"
        />
      );
    case LIGHTS_TYPES.resettlement:
      return (
        <DynamicsRatingBase
          id={light.id}
          fractionDigits={1}
          valueText="Факт расселения"
        />
      );
    case LIGHTS_TYPES.sharedConstruction:
      return (
        <DynamicsRatingBase
          id={light.id}
          dynamicsField="118"
          fractionDigits={1}
          valueText="Проблемные дома (прирост)"
        />
      );
    default:
      return (
        <DynamicsRatingBase
          id={light.id}
          fractionDigits={1}
          valueText="Кассовое исполнение"
        />
      );
  }
};
