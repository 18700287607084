import { Collapse } from "antd";
import { createElement, FC } from "react";

import { useAppSelector } from "../../redux/hooks";
import { LIGHTS_TYPES } from "../../redux/slices/lights/api/scheme";
import * as styles from "./CardSubjectSummary.module.scss";
import { CardSubjectSummaryBaikal } from "./lights/CardSubjectSummaryBaikal";
import { CardSubjectSummaryCity } from "./lights/CardSubjectSummaryCity";
import { CardSubjectSummaryHousing } from "./lights/CardSubjectSummaryHousing";
import { CardSubjectSummaryMortgage } from "./lights/CardSubjectSummaryMortgage";
import { CardSubjectSummaryProject } from "./lights/CardSubjectSummaryProject";
import { CardSubjectSummaryResettlement } from "./lights/CardSubjectSummaryResettlement";
import { CardSubjectSummaryRoads } from "./lights/CardSubjectSummaryRoads";
import { CardSubjectSummarySharedConstruction } from "./lights/CardSubjectSummarySharedConstruction";
import { CardSubjectSummaryStimul } from "./lights/CardSubjectSummaryStimul";
import { CardSubjectSummaryVolga } from "./lights/CardSubjectSummaryVolga";
import { CardSubjectSummaryWater } from "./lights/CardSubjectSummaryWater";
import { ICardSubjectSummaryProps } from "./scheme";

const { Panel } = Collapse;

const PANELS = {
  [LIGHTS_TYPES.housing]: CardSubjectSummaryHousing,
  [LIGHTS_TYPES.roads]: CardSubjectSummaryRoads,
  [LIGHTS_TYPES.sharedConstruction]: CardSubjectSummarySharedConstruction,
  [LIGHTS_TYPES.stimul]: CardSubjectSummaryStimul,
  [LIGHTS_TYPES.resettlement]: CardSubjectSummaryResettlement,
  [LIGHTS_TYPES.mortgage]: CardSubjectSummaryMortgage,
  [LIGHTS_TYPES.project]: CardSubjectSummaryProject,
  [LIGHTS_TYPES.city]: CardSubjectSummaryCity,
  [LIGHTS_TYPES.water]: CardSubjectSummaryWater,
  [LIGHTS_TYPES.recoveryVolga]: CardSubjectSummaryVolga,
  [LIGHTS_TYPES.baikal]: CardSubjectSummaryBaikal,
};

export const CardSubjectSummary: FC<ICardSubjectSummaryProps> = ({ id }) => {
  const { items } = useAppSelector((state) => state.lights.list);

  return (
    <Collapse accordion>
      {items
        .filter((light) => PANELS[light.type as keyof typeof PANELS])
        .map((light) => (
          <Panel
            className={styles.panel}
            header={light.attributeVals.Наименование}
            key={light.id}
          >
            {createElement(PANELS[light.type as keyof typeof PANELS], { id })}
          </Panel>
        ))}
    </Collapse>
  );
};
