import {
  getOperand,
  olapRequestDimensionData,
} from "../../../../core/api/core";
import {
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  getModel,
  IPreparedOlapResponseDimensionItemDim,
} from "../../../../core/scheme/olap";
import { REGION_COUNTRY_CODE } from "./scheme";

export const REGIONS_STARTER: IPreparedOlapResponseDimensionItemDim = {
  id: REGION_COUNTRY_CODE,
  code: REGION_COUNTRY_CODE,
  attributeVals: {
    NAME: "",
    SHORT_NAME: "",
    UNIT: "",
    Наименование: "Российская Федерация",
    SORT_ORDER: 0,
  },
  childrenCnt: 0,
  level: 0,
  parentId: [],
  queryParentId: [],
};

export const getDistricts = () => {
  const model = getModel();

  return olapRequestDimensionData(model.dataRegionId, {
    modelUuid: model.uuid,
    dimensionId: model.dataRegionId,
    dataFilter: {
      type: FILTER_TYPE.STARTER,
      dim_id: model.dataRegionId,
    },
    includeAttributesByCodes: [
      "NAME",
      "SHORT_NAME",
      "SORT_ORDER",
      "Наименование",
    ],
  });
};

export const getRegions = (districts: string[]) => {
  const model = getModel();

  return olapRequestDimensionData(model.dataRegionId, {
    modelUuid: model.uuid,
    dimensionId: model.dataRegionId,
    dataFilter: getOperand({
      type: FILTER_OPERAND_TYPE.PARENT,
      dim_id: model.dataRegionId,
      values: districts,
    }),
    includeAttributesByCodes: [
      "NAME",
      "SHORT_NAME",
      "SORT_ORDER",
      "Наименование",
    ],
  });
};
