import { useCallback } from "react";
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useNavigate,
} from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";

export const useRouter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rrNavigate = useNavigate();

  const getPath = useCallback(
    (to: To) => {
      const toPath =
        typeof to === "string"
          ? { pathname: to.split("?")[0], search: to.split("?")[1] }
          : to;

      const params = createSearchParams(searchParams);
      createSearchParams(toPath.search).forEach((value, key) => {
        params.set(key, value);
      });

      return { pathname: toPath.pathname ?? "", search: params.toString() };
    },
    [searchParams]
  );

  const navigate = useCallback<NavigateFunction>(
    (to, options?) => {
      if (typeof to === "number") {
        return rrNavigate(to); // (delta: number): void
      } else {
        return rrNavigate(getPath(to), options as NavigateOptions);
      }
    },
    [getPath, rrNavigate]
  );

  return { searchParams, setSearchParams, navigate, getPath };
};
