import { Card } from "antd";
import clsx from "clsx";
import { FC } from "react";

import { LIGHT_RATES } from "../../../redux/slices/lights/api/rate/rate";
import * as styles from "./CardInfo.module.scss";

interface ICardInf {
  value?: number | string;
  description: string;
  rate?: LIGHT_RATES;
  subRate?: LIGHT_RATES;
  percent?: boolean;
}

export const CardInfo: FC<ICardInf> = ({
  value = "-",
  description,
  rate,
  subRate,
  percent,
}) => {
  return (
    <Card className={styles.cardInfo}>
      <div className={styles.cardInfoNumber}>
        <div
          className={clsx(
            rate && styles[`cardInfoRate-${rate}` as keyof typeof styles]!,
            subRate &&
              styles[`cardInfoSubRate-${subRate}` as keyof typeof styles]!
          )}
        >
          {percent ? `${value}%` : value}
        </div>
      </div>
      {description && (
        <div className={styles.cardInfoDescription}>{description}</div>
      )}
    </Card>
  );
};
