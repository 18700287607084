import { ILightRow } from "../../../../../core/scheme/olap";
import { LIGHTS_TYPES } from "../scheme";
import getBaikalRate from "./baikal";
import getCityRate from "./city";
import getHousingRate from "./housing";
import getMortgageRate from "./morgage";
import getMortgageFarEast from "./morgageFarEast";
import getMortgageGov from "./morgageGov";
import getMortgageGovBenefits from "./morgageGovBenefits";
import getMortgageGovFamily from "./morgageGovFamily";
import getMortgagePrimary from "./morgagePrimary";
import getMortgageVillage from "./morgageVillage";
import getProjectRate from "./project";
import getRecoveryVolga from "./recoveryVolga";
import getResettlementRate from "./resettlement";
import getRoadsRate from "./roads";
import getRoadsDeadRate from "./roadsDead";
import getRoadsInfrastructureRate from "./roadsInfrastructure";
import getRoadsRegionalRate from "./roadsRegional";
import getSharedConstructionRate from "./sharedConstruction";
import getStimulRate from "./stimul";
import getWaterRate from "./water";

export enum LIGHT_RATES {
  low = "low",
  middle = "middle",
  high = "high",
}

export const LIGHT_RATE_FUNCS: {
  [type in LIGHTS_TYPES]?: (
    item: ILightRow,
    rows: ILightRow[]
  ) => LIGHT_RATES | undefined;
} = {
  [LIGHTS_TYPES.city]: getCityRate,
  [LIGHTS_TYPES.housing]: getHousingRate,
  [LIGHTS_TYPES.mortgage]: getMortgageRate,
  [LIGHTS_TYPES.mortgageGov]: getMortgageGov,
  [LIGHTS_TYPES.mortgageGovBenefits]: getMortgageGovBenefits,
  [LIGHTS_TYPES.mortgageGovFamily]: getMortgageGovFamily,
  [LIGHTS_TYPES.mortgageFarEast]: getMortgageFarEast,
  [LIGHTS_TYPES.mortgageVillage]: getMortgageVillage,
  [LIGHTS_TYPES.mortgagePrimary]: getMortgagePrimary,
  [LIGHTS_TYPES.project]: getProjectRate,
  [LIGHTS_TYPES.resettlement]: getResettlementRate,
  [LIGHTS_TYPES.roads]: getRoadsRate,
  [LIGHTS_TYPES.roadsDead]: getRoadsDeadRate,
  [LIGHTS_TYPES.roadsRegional]: getRoadsRegionalRate,
  [LIGHTS_TYPES.roadsInfrastructure]: getRoadsInfrastructureRate,
  [LIGHTS_TYPES.sharedConstruction]: getSharedConstructionRate,
  [LIGHTS_TYPES.stimul]: getStimulRate,
  [LIGHTS_TYPES.water]: getWaterRate,
  [LIGHTS_TYPES.recoveryVolga]: getRecoveryVolga,
  [LIGHTS_TYPES.baikal]: getBaikalRate,
};
