import { combineReducers } from "@reduxjs/toolkit";

import auth, { IAuthReducer } from "./slices/auth/reducer";
import lights, { ILightsReducer } from "./slices/lights/reducer";
import regions, { IRegionsReducer } from "./slices/regions/reducer";

export interface IState {
  auth: IAuthReducer;
  lights: ILightsReducer;
  regions: IRegionsReducer;
}

const reducer = combineReducers({
  auth,
  lights,
  regions,
});

export default reducer;
