import { combineReducers } from "@reduxjs/toolkit";

import districtsSlice, { IDistrictsState } from "./districtsSlice";
import regionsSlice, { IRegionsState } from "./regionsSlice";

export interface IRegionsReducer {
  districts: IDistrictsState;
  regions: IRegionsState;
}

const reducer = combineReducers<IRegionsReducer>({
  districts: districtsSlice.reducer,
  regions: regionsSlice.reducer,
});

export default reducer;
