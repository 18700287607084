import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";

export const DATA_PERIOD_VALUE = "120210000";
export const DATA_PERIOD_2020W53 = "520200053";
export const DATA_PERIOD_2021W18 = "520210018";
export const DATA_PERIOD_2021W41 = "520210041";
export const DATA_PERIOD_2021W42 = "520210042";
export const DATA_SOURCE_VALUE = "1"; // Все источники
export const DATA_ACTUAL_VALUE = "2"; // Признак актуальности

export const MODELS = {
  STANDARD: {
    uuid: "11bda660-22f3-43c7-9eed-a5cf6285b7bc",
    dimId: "5020",
    indicatorId: "5029",
    sumIndicatorId: "5030",
    dataPeriodId: "5005",
    dataSourceId: "5026",
    dataActualId: "5032",
    dataRegionId: "5013",
  },
  DIRECT_QUERY: {
    uuid: "9d72e4b3-9916-438c-9a6f-9ec707384daf",
    dimId: "5110",
    indicatorId: "5123",
    sumIndicatorId: "5124",
    dataPeriodId: "5094",
    dataSourceId: "5117",
    dataActualId: "5120",
    dataRegionId: "5103",
  },
};

export const MODEL: keyof typeof MODELS = "DIRECT_QUERY";

export const getModel = () => MODELS[MODEL];

export enum REQUEST_TYPE {
  DIMENSION_DATA = "dimension_data",
  DATA2 = "data2",
}

export enum SORT {
  ASC = "asc",
  DESC = "desc",
}

export enum FILTER_TYPE {
  AND = "AND",
  OR = "OR",
  STARTER = "STARTER",
}

export enum FILTER_OPERAND_TYPE {
  LEVEL = "LEVEL",
  PARENT = "PARENT",
  EQ = "EQ",
  GE = "GE",
  DIM = "DIM",
  CONST = "CONST",
}

export interface IBaseAttributeVals {
  NAME: string;
  SHORT_NAME: string;
  UNIT: string;
}

export interface IIndicatorAttributeVals extends IBaseAttributeVals {
  Наименование: string;
  SORT_ORDER: string;
}

export interface IPreparedIndicatorAttributeVals
  extends Omit<IIndicatorAttributeVals, "SORT_ORDER"> {
  SORT_ORDER: number;
}

export interface IPeriodAttributeVals extends IIndicatorAttributeVals {
  REPORT_DATE: string;
}

export type IAttributeVals = IIndicatorAttributeVals | IPeriodAttributeVals;

export interface IDataFilterOperand {
  type: FILTER_OPERAND_TYPE;
  dim_id?: string;
  version?: number;
}

export interface IDataFilterOperandLevel extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.LEVEL;
  levels: number[];
  rootIds?: string[];
  showAllLevelElements?: boolean;
  showChildren?: boolean;
  showLeafsOnly?: boolean;
}

export interface IDataFilterOperandParent extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.PARENT;
  values: string[];
}

export interface IDataFilterOperandDim extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.DIM;
  id: string;
}

export interface IDataFilterOperandConst extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.CONST;
  values: string[];
}

export interface IDataFilterOperandCompare extends IDataFilterOperand {
  type: FILTER_OPERAND_TYPE.EQ | FILTER_OPERAND_TYPE.GE;
  operands: IDataFilterOperandAny[];
}

export type IDataFilterOperandAny =
  | IDataFilterOperandLevel
  | IDataFilterOperandParent
  | IDataFilterOperandDim
  | IDataFilterOperandConst
  | IDataFilterOperandCompare;

export interface IDataFilterStarter {
  type: FILTER_TYPE.STARTER;
  dim_id: string;
}

export interface IDataFilterWithOperands {
  type: FILTER_TYPE.AND | FILTER_TYPE.OR;
  operands: (IDataFilterOperandAny | IDataFilter)[];
}

export type IDataFilter = (IDataFilterStarter | IDataFilterWithOperands) & {
  version?: number;
};

export const isDataFilterWithOperands = (
  filter: IDataFilter | IDataFilterOperand
): filter is IDataFilterWithOperands => {
  return (filter as IDataFilterWithOperands).operands !== undefined;
};

export interface IDataSort {
  dim_id: string;
  sortField: string;
  sortOrder: SORT;
  version?: number;
}

export interface IOlapRequestBaseParams {
  modelUuid: string;
  dataSort?: IDataSort[];
  dataFilter?: IDataFilter | IDataFilterOperand;
  includeAttributesByCodes?: string[];
  includeGaps?: boolean;
  dataRequestCaching?: boolean;
}

export interface IOlapParamsDimensionData extends IOlapRequestBaseParams {
  dimensionId: string;
}

export interface IOlapParamsData2 extends IOlapRequestBaseParams {
  indicatorId: string;
  dimensions: any; //TODO
  indicators: any; //TODO
}

export interface IOlapRequestDimensionData {
  type: REQUEST_TYPE.DIMENSION_DATA;
  params: IOlapParamsDimensionData;
}

export interface IOlapRequestData2 {
  type: REQUEST_TYPE.DATA2;
  params: IOlapParamsData2;
}

export type IOlapRequest = (IOlapRequestDimensionData | IOlapRequestData2) & {
  requestId?: string;
};

export interface IOlapResponseDataItem {
  dims: { [dimId: string]: string };
  indicatorVals: { [key: string]: { sum: number } };
}

export interface IOlapResponseDimensionItemDim {
  id: string;
  code: string;
  attributeVals: IIndicatorAttributeVals;
  childrenCnt: number;
  level: number;
  parentId: string[];
  queryParentId: string[];
}

export interface IPreparedOlapResponseDimensionItemDim
  extends Omit<IOlapResponseDimensionItemDim, "attributeVals"> {
  attributeVals: IPreparedIndicatorAttributeVals;
}

export interface IOlapResponseDimensionItems {
  [dimId: string]: {
    [id: string]: IOlapResponseDimensionItemDim;
  };
}

export interface IOlapResponseDimensionItemDimWithVals
  extends IOlapResponseDimensionItemDim {
  indicatorVals: IOlapResponseDataItem["indicatorVals"];
  dims: { [dimId: string]: IOlapResponseDimensionItemDimWithVals };
}

export interface IOlapResponseDataDimensionData {
  items: IOlapResponseDimensionItemDim[];
}

export interface IOlapResponseDataData2 {
  data: IOlapResponseDataItem[];
  dimensionItems: IOlapResponseDimensionItems;
}

export interface IOlapResponseDimensionsData {
  responses: {
    [id: string]: {
      requestId: string;
      type: REQUEST_TYPE.DIMENSION_DATA;
      data: IOlapResponseDataDimensionData;
    };
  };
}

export interface IOlapResponseData2 {
  responses: {
    [id: string]: {
      requestId: string;
      type: REQUEST_TYPE.DATA2;
      data: IOlapResponseDataData2;
    };
  };
}

export type IOlapResponse = IOlapResponseDimensionsData | IOlapResponseData2;

export interface ILightIndicator {
  id: string;
  code: string;
  date?: string;
  district?: string;
  level?: number;
  projectId?: string;
  value: number;
  attributeVals: IIndicatorAttributeVals;
}

export interface IPreparedLightIndicator
  extends Omit<ILightIndicator, "attributeVals"> {
  attributeVals: IPreparedIndicatorAttributeVals;
}

export interface ILightRow {
  [indicatorId: string]: IPreparedLightIndicator;
}

export type ILightTableRow = {
  sn: number;
  region: IPreparedLightIndicator;
  key?: string;
  rate?: LIGHT_RATES;
} & ILightRow;

export interface ILightData {
  [lightId: string]: ILightRow;
}
