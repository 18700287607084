export const DATE_DEFAULT_FORMAT = "DD.MM.YYYY";
export const DATE_DISPLAY_FORMAT = "DD MMMM YYYY";

export enum LIGHTS_TYPES {
  housing = "housing",
  resettlement = "resettlement",
  stimul = "stimul",
  project = "project",
  city = "city",
  roads = "roads",
  water = "water",
  mortgage = "mortgage",
  mortgageGov = "mortgageGov",
  mortgageGovBenefits = "mortgageGovBenefits",
  mortgageGovFamily = "mortgageGovFamily",
  mortgageFarEast = "mortgageFarEast",
  mortgageVillage = "mortgageVillage",
  mortgagePrimary = "mortgagePrimary",
  sharedConstruction = "sharedConstruction",
  recoveryVolga = "recoveryVolga",
  baikal = "baikal",
  roadsRegional = "roadsRegional",
  roadsInfrastructure = "roadsInfrastructure",
  roadsDead = "roadsDead",
}

export enum COMMON_LIGHTS_TYPES {
  budget = "budget",
}

export const LIGHTS_MAP_TYPES = {
  "10000": LIGHTS_TYPES.housing,
  "20000": LIGHTS_TYPES.roads,
  "30000": LIGHTS_TYPES.resettlement,
  "40000": LIGHTS_TYPES.stimul,
  "50000": LIGHTS_TYPES.project,
  "60000": LIGHTS_TYPES.water,
  "70000": LIGHTS_TYPES.city,
  "80000": LIGHTS_TYPES.mortgage,
  "160000": LIGHTS_TYPES.mortgageGov,
  "170000": LIGHTS_TYPES.mortgageGovBenefits,
  "180000": LIGHTS_TYPES.mortgageGovFamily,
  "190000": LIGHTS_TYPES.mortgageFarEast,
  "230000": LIGHTS_TYPES.mortgageVillage,
  "200000": LIGHTS_TYPES.mortgagePrimary,
  "90000": LIGHTS_TYPES.sharedConstruction,
  "100000": LIGHTS_TYPES.roadsRegional,
  "110000": LIGHTS_TYPES.roadsInfrastructure,
  "120000": LIGHTS_TYPES.roadsDead,
  "220000": LIGHTS_TYPES.recoveryVolga,
  "210000": LIGHTS_TYPES.baikal,
};

export const COMMON_LIGHTS_MAP_TYPES = {
  "150000": COMMON_LIGHTS_TYPES.budget,
};

export const LIGHTS_MAP = Object.keys(LIGHTS_MAP_TYPES).reduce(
  (prev, key) => ({
    ...prev,
    [LIGHTS_MAP_TYPES[key as keyof typeof LIGHTS_MAP_TYPES]]: key,
  }),
  {} as { [type in LIGHTS_TYPES]: string }
);
