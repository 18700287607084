import { Card } from "antd";
import moment from "moment";
import React from "react";

import { formatNumber } from "../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../redux/slices/lights/api/scheme";
import {
  LIGHT_FACT,
  LIGHT_FIELD_DYNAMICS,
  LIGHT_PLAN,
  useLight,
} from "../../redux/slices/lights/hooks/useLight";
import { CardHeader } from "../CardHeader/CardHeader";
import { CardSubStatsContent } from "../CardSubStatsContent/CardSubStatsContent";
import { Empty } from "../Empty";
import { PlanFactProgressBar } from "../PlanFactProgressBar/PlanFactProgressBar";

type PropsType = {
  big: boolean;
  id: string;
};

export const CardRoadsMetrics: React.FC<PropsType> = ({ id, big }) => {
  const { total } = useLight(LIGHTS_MAP.roads);

  return (
    <Card
      bordered={false}
      title={
        <CardHeader
          big={big}
          title="Кассовое исполнение, %"
          subTitle="Безопасные и качественные дороги (БКД)"
          helperText={
            total
              ? `Данные на ${moment(total["29"]?.date).format(
                  DATE_DISPLAY_FORMAT
                )}`
              : ""
          }
        />
      }
    >
      {total ? (
        <>
          <PlanFactProgressBar
            plan={LIGHT_PLAN[LIGHTS_TYPES.roads](total)?.value}
            fact={LIGHT_FACT[LIGHTS_TYPES.roads](total)?.value}
            dynamics={total[LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roads]]?.value}
            unit={LIGHT_PLAN[LIGHTS_TYPES.roads](total)?.attributeVals.UNIT}
          />
          <CardSubStatsContent
            title="Площадь выполнения дорожных работ"
            titleRight={` · ${total["29"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["30"]?.value, undefined, 0),
                valueSize: big ? "big" : "small",
                footerText: "Факт",
              },
              {
                value: formatNumber(total["29"]?.value, undefined, 0),
                valueSize: big ? "big" : "small",
                footerText: "План",
              },
            ]}
          />
          <CardSubStatsContent
            title="Резервный фонд правительства РФ"
            titleRight={` · ${total["34"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["34"]?.value, undefined, 0),
                footerText: "Исполнено",
                valueSize: big ? "big" : "small",
              },
              {
                value: formatNumber(total["32"]?.value, undefined, 0),
                footerText: "Предусмотрено",
                valueSize: big ? "big" : "small",
              },
            ]}
          />
          <CardSubStatsContent
            title="Количество погибших"
            titleRight={` · чел`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["254"]?.value, undefined, 0),
                footerText: "Всего",
                valueSize: big ? "big" : "small",
              },
              {
                value: formatNumber(total["256"]?.value, undefined, 0),
                footerText: "На фед.\nдорогах",
                valueSize: big ? "big" : "small",
              },
              {
                value: formatNumber(total["258"]?.value, undefined, 0),
                footerText: "На рег. и мун.\nдорогах",
                valueSize: big ? "big" : "small",
              },
            ]}
          />
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
