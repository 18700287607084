import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.roads;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryRoads: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, district, districtRate, total, totals, fetching } =
    useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["24"]
          ? moment(data.curr["24"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["24"]
              ? formatNumber(data.curr["24"].value, undefined, 1)
              : "-"
          }
          description="млн. руб. кассовое исполнение, в том числе, ФБ"
        />
        <CardInfo
          value={
            data?.curr["25"] ? formatNumber(data.curr["25"].value, "%", 2) : "-"
          }
          description="% кассового исполнения, в том числе, ФБ"
        />
        <CardInfo
          value={
            data?.curr["22"] && data?.curr["24"]
              ? formatNumber(
                  data.curr["22"].value - data.curr["24"].value,
                  undefined,
                  1
                )
              : "-"
          }
          description="млн. руб. кассовое исполнение, в том числе, бюджет субъекта РФ"
        />
        <CardInfo
          value={
            data?.curr["25"]
              ? formatNumber(100 - data.curr["25"].value, "%", 2)
              : "-"
          }
          description="% кассового исполнение, в том числе, бюджет субъекта РФ"
        />
      </div>
      <div>
        <div className={clsx(styles.heading, styles.marginTop48)}>
          Объем финансирование, {data?.curr["20"]?.attributeVals.UNIT}
        </div>
        <div className={styles.flex}>
          <div className={styles.width33}>
            <div className={styles.count}>
              {totals?.[LIGHT_ID]?.["20"]
                ? formatNumber(totals[LIGHT_ID]["20"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>РФ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {district?.[LIGHT_ID]?.["20"]
                ? formatNumber(district[LIGHT_ID]["20"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>Федеральный округ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {data?.curr["20"]
                ? formatNumber(data.curr["20"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>Субъект РФ</span>
          </div>
        </div>

        <div className={clsx(styles.heading, styles.marginTop48)}>
          Кассовое исполнение, {data?.curr["22"]?.attributeVals.UNIT}
        </div>
        <div className={styles.flex}>
          <div className={styles.width33}>
            <div className={styles.count}>
              {totals?.[LIGHT_ID]?.["22"]
                ? formatNumber(totals[LIGHT_ID]["22"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>РФ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {district?.[LIGHT_ID]?.["22"]
                ? formatNumber(district[LIGHT_ID]["22"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>Федеральный округ</span>
          </div>
          <div className={styles.width33}>
            <div className={styles.count}>
              {data?.curr["22"]
                ? formatNumber(data.curr["22"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>Субъект РФ</span>
          </div>
        </div>

        <div className={clsx(styles.heading, styles.marginTop48)}>
          Кассовое исполнение, {data?.curr["23"]?.attributeVals.UNIT}
        </div>
        <div className={styles.flex}>
          <div className={styles.width33}>
            <div className={styles.count}>
              {totals?.[LIGHT_ID]?.["23"]
                ? formatNumber(totals[LIGHT_ID]["23"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>РФ</span>
          </div>
          <div className={styles.width33}>
            <div
              className={clsx(
                styles.count,
                styles[districtRate as keyof typeof styles]
              )}
            >
              {district?.[LIGHT_ID]?.["23"]
                ? formatNumber(district[LIGHT_ID]["23"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>Федеральный округ</span>
          </div>
          <div className={styles.width33}>
            <div
              className={clsx(
                styles.count,
                styles[total?.rate as keyof typeof styles]
              )}
            >
              {data?.curr["23"]
                ? formatNumber(data.curr["23"]?.value, undefined, 1)
                : "-"}
            </div>
            <span className={styles.info}>Субъект РФ</span>
          </div>
        </div>
      </div>
    </>
  );
};
