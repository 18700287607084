import { Card as AntdCard, Spin } from "antd";
import React, { FC } from "react";

import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import { useLight } from "../../redux/slices/lights/hooks/useLight";
import { CardMetrics } from "../CardMetrics/CardMetrics";
import { TotalRateProgressBar } from "../TotalRateProgressBar/TotalRateProgressBar";
import * as styles from "./Card.module.scss";

export interface ICardProps {
  id: string;
  big: boolean;
}

export const Card: FC<ICardProps> = ({ id, big }) => {
  const { light, total, totalRates, fetching } = useLight(id);

  return light ? (
    <AntdCard className={styles.card}>
      {fetching ? (
        <div className={styles.loader}>
          <Spin />
        </div>
      ) : (
        <>
          <CardMetrics id={id} />
          {!big &&
            total &&
            (totalRates ? (
              <TotalRateProgressBar
                redCircle={totalRates[LIGHT_RATES.low].length}
                orangeCircle={totalRates[LIGHT_RATES.middle].length}
                greenCircle={totalRates[LIGHT_RATES.high].length}
                redText="Низкий"
                orangeText="Средний"
                greenText="Высокий"
              />
            ) : (
              <div className={styles.rateLoader}>
                <Spin />
              </div>
            ))}
        </>
      )}
    </AntdCard>
  ) : null;
};
