import { Card } from "antd";
import moment from "moment";
import React from "react";

import { formatNumber } from "../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_TYPES,
} from "../../redux/slices/lights/api/scheme";
import {
  LIGHT_FACT,
  LIGHT_FIELD_DYNAMICS,
  LIGHT_PLAN,
  useLight,
} from "../../redux/slices/lights/hooks/useLight";
import { CardHeader } from "../CardHeader/CardHeader";
import { CardSubStatsContent } from "../CardSubStatsContent/CardSubStatsContent";
import { Empty } from "../Empty";
import { PlanFactProgressBar } from "../PlanFactProgressBar/PlanFactProgressBar";

type PropsType = {
  big: boolean;
  id: string;
};

export const CardBaikalMetrics: React.FC<PropsType> = ({ big, id }) => {
  const { total } = useLight(id);

  return (
    <Card
      bordered={false}
      title={
        <CardHeader
          big={big}
          title="Кассовое исполнение, %"
          subTitle="Байкал"
          helperText={
            total
              ? `Данные на ${moment(total["235"]?.date).format(
                  DATE_DISPLAY_FORMAT
                )}`
              : ""
          }
        />
      }
    >
      {total ? (
        <>
          <PlanFactProgressBar
            plan={LIGHT_PLAN[LIGHTS_TYPES.baikal](total)?.value}
            fact={LIGHT_FACT[LIGHTS_TYPES.baikal](total)?.value}
            dynamics={total[LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.baikal]]?.value}
            unit={LIGHT_PLAN[LIGHTS_TYPES.baikal](total)?.attributeVals.UNIT}
          />
          <CardSubStatsContent
            title="Законтрактованность"
            titleRight={` · ${total["235"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["235"]?.value || 0, undefined, 1),
                valueSize: big ? "big" : "small",
              },
            ]}
          />
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
