import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IPreparedOlapResponseDimensionItemDim } from "../../../core/scheme/olap";
import { prepareAttributes } from "../lights/utils";
import { getDistricts } from "./api/client";

export interface IDistrictsState {
  items?: IPreparedOlapResponseDimensionItemDim[];
  fetching: boolean;
}

export const initialState: IDistrictsState = {
  items: undefined,
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk(
    "lights/regions/districts",
    async () => await getDistricts()
  ),
};

const slice = createSlice({
  name: "lightsRegions/Districts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.items = action.payload.map((item) => ({
          ...item,
          attributeVals: prepareAttributes(item.attributeVals),
        }));
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
