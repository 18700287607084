import React, { ReactNode } from "react";

import { TextValue, TextValueProps } from "../TextValue/TextValue";
import * as styles from "./CardSubStatsContent.module.scss";

export type CardSubStatsContentProps = {
  title: ReactNode;
  titleRight?: ReactNode;
  data: TextValueProps[];
  sm?: boolean;
};

export const CardSubStatsContent: React.FC<CardSubStatsContentProps> = ({
  title,
  titleRight,
  data,
  sm,
}) => (
  <div>
    <div className={styles.cardSubContent}>
      <div
        className={
          sm ? styles.cardSubContentHeaderSm : styles.cardSubContentHeader
        }
      >
        {title}
        {titleRight && (
          <div
            className={
              sm
                ? styles.cardSubContentHeaderRightSm
                : styles.cardSubContentHeaderRight
            }
          >
            {titleRight}
          </div>
        )}
      </div>
      <div className={styles.cardSubContentBody}>
        {data.map((p, i) => (
          <div key={i} className={styles.cardSubContentItem}>
            <TextValue {...p} />
          </div>
        ))}
      </div>
    </div>
  </div>
);
