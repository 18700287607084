import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.city;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryCity: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, district, districtRate, total, totals, fetching } =
    useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["90"]
          ? moment(data.curr["90"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["90"]
              ? formatNumber(data.curr["90"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["90"]?.attributeVals.UNIT ?? ""
          }, утвержденные БА`}
        />
        <CardInfo
          value={
            data?.curr["91"]
              ? formatNumber(data.curr["91"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["91"]?.attributeVals.UNIT ?? ""
          }, кассовое исполнение`}
        />
        <CardInfo
          value={
            data?.curr["92"]
              ? formatNumber(
                  data.curr["92"].value,
                  data.curr["92"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["95"]
              ? formatNumber(
                  data.curr["95"].value,
                  data.curr["95"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% законтрактованности"
        />
      </div>
      <div>
        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Общее количество территорий, шт.
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["96"]
                    ? formatNumber(data.curr["96"]?.value, undefined, 0)
                    : "-"}
                </div>
                <span className={styles.info}>Общественные</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["99"]
                    ? formatNumber(data.curr["99"]?.value, undefined, 0)
                    : "-"}
                </div>
                <span className={styles.info}>Дворовые</span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Кассовое исполнение,{" "}
            {totals?.[LIGHT_ID]?.["92"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["92"]
                    ? formatNumber(
                        totals[LIGHT_ID]["92"]?.value,
                        totals[LIGHT_ID]["92"]?.attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div
                  className={clsx(
                    styles.count,
                    styles[districtRate as keyof typeof styles]
                  )}
                >
                  {district?.[LIGHT_ID]?.["92"]
                    ? formatNumber(
                        district[LIGHT_ID]["92"].value,
                        district[LIGHT_ID]["92"]?.attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Завершенное количество территорий, шт.
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["97"]
                    ? formatNumber(data.curr["97"]?.value, undefined, 0)
                    : "-"}
                </div>
                <span className={styles.info}>Общественные</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["101"]
                    ? formatNumber(data.curr["101"]?.value, undefined, 0)
                    : "-"}
                </div>
                <span className={styles.info}>Дворовые</span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Законтрактованность,{" "}
            {totals?.[LIGHT_ID]?.["95"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["95"]
                    ? formatNumber(
                        totals[LIGHT_ID]["95"]?.value,
                        totals[LIGHT_ID]["95"].attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {district?.[LIGHT_ID]?.["95"]
                    ? formatNumber(
                        district[LIGHT_ID]["95"].value,
                        district[LIGHT_ID]["95"]?.attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
