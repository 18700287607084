import { FC } from "react";

import { useAppSelector } from "../../redux/hooks";
import { FilterDate } from "./filters/FilterDate";
import { FilterRegions } from "./filters/FilterRegions";

export const HeaderFilters: FC = () => {
  const { user } = useAppSelector((state) => state.auth.user);

  if (!user) {
    return null;
  }

  return (
    <>
      <FilterDate />
      <FilterRegions />
    </>
  );
};
