import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IOlapResponseDimensionItemDim } from "../../../core/scheme/olap";
import { getList } from "./api/client";
import { LIGHTS_MAP_TYPES, LIGHTS_TYPES } from "./api/scheme";

export interface ILightListItem extends IOlapResponseDimensionItemDim {
  type: LIGHTS_TYPES;
}

export interface ILightsListState {
  items: ILightListItem[];
  fetching: boolean;
}

export const initialState: ILightsListState = {
  items: [],
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk("lights/list", async () => await getList()),
};

const slice = createSlice({
  name: "lightsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.items = action.payload
          .filter(
            (item) => LIGHTS_MAP_TYPES[item.id as keyof typeof LIGHTS_MAP_TYPES]
          )
          .map((item) => ({
            ...item,
            type: LIGHTS_MAP_TYPES[item.id as keyof typeof LIGHTS_MAP_TYPES],
          }));
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.items = [];
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
