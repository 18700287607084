import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";

import { useRouter } from "./useRouter";

export const useDate = () => {
  const { searchParams, setSearchParams } = useRouter();

  const treeData = useMemo(() => {
    const today = moment();
    const todayWeek = today.week();
    const todayYear = today.year();
    const startYear = 2021;
    const result = [];

    for (let year = startYear; year <= todayYear; year++) {
      const weeksCount = year < todayYear ? 52 : todayWeek;
      const children = new Array(weeksCount).fill(0).map((_, index) => {
        const weekNumber = index + 1;
        const week =
          year === todayYear && weekNumber === todayWeek
            ? today
            : moment().isoWeekYear(year).isoWeek(weekNumber).startOf("week");

        return {
          value: week.format(`[5]YYYY[00]ww`),
          title: week.format("[Неделя] w (DD.MM.YYYY)"),
        };
      });

      result.push({
        value: `1${year}0000`,
        title: `${year} год`,
        children,
      });
    }

    return result;
  }, []);

  const value = useMemo(() => {
    const date = searchParams.get("date") ?? "";

    if (date) {
      // Ищем переданную дату в списке
      for (let year of treeData) {
        if (date === year.value) {
          return date;
        }

        if (year.children.find((week) => week.value === date)) {
          return date;
        }
      }
    }

    // Если дата не передана или передана некорректная, вернем весь текущий год по умолчанию
    return treeData[treeData.length - 1].value;
  }, [searchParams, treeData]);

  const setDate = useCallback(
    (date: string) => {
      setSearchParams({ date });
    },
    [setSearchParams]
  );

  useEffect(() => {
    // Выставим дефолтное значение при загрузке страницы,
    // чтобы оно было явно прописано в адресной строке
    setSearchParams({ date: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { date: value, setDate, treeData };
};
