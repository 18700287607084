import { AxiosBasicCredentials } from "axios";

import client from "../../../../core/api/client";

export const AUTH_API_BASEPATH = "/api/auth_api/v1.0";

export const getToken = (credentials?: AxiosBasicCredentials) =>
  client.post<{
    access_token: string;
    refresh_token: string;
    token_type: string;
  }>(
    `${AUTH_API_BASEPATH}/oauth/token?grant_type=password&client_id=planet-analitics&client_secret=planet-analitics-password${
      credentials
        ? `&username=${credentials.username}&password=${credentials.password}`
        : ""
    }`
  );

export interface IUser {
  changePassRequired: boolean;
  email: string | null;
  expireDate: string | null;
  firstName: string | null;
  id: number;
  lastName: string | null;
  login: string;
  middleName: string | null;
  name: string;
}

export const getAuthorized = () =>
  client.get<IUser>(`${AUTH_API_BASEPATH}/user/authorized`);
