import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getModel, ILightTableRow } from "../../../core/scheme/olap";
import { IRootState } from "../../store";
import { REGION_COUNTRY_CODE } from "../regions/api/scheme";
import { getLightDynamics } from "./api/client";
import { LIGHT_RATE_FUNCS } from "./api/rate/rate";
import { LIGHTS_MAP_TYPES } from "./api/scheme";
import {
  LIGHT_FIELD_DYNAMICS,
  LIGHT_FIELD_DYNAMICS_SORT,
} from "./hooks/useLight";
import { getRegion, prepareAttributes } from "./utils";

export interface ILightsTotalRateData {
  [lightId: string]: ILightTableRow[];
}

export interface ILightsTotalRateState {
  data?: ILightsTotalRateData;
  fetching: boolean;
}

export const initialState: ILightsTotalRateState = {
  data: undefined,
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk(
    "lights/total/rates",
    async (date: string, thunkAPI) =>
      await getLightDynamics({
        fieldIds: Object.values(LIGHT_FIELD_DYNAMICS),
        fieldLevels: [0],
        regionIds: [REGION_COUNTRY_CODE],
        regionLevels: [0, 2],
        periodIds: [date],
      }).then((data) => ({
        data,
        regions:
          (thunkAPI.getState() as IRootState).regions.regions.items ?? [],
      }))
  ),
};

const slice = createSlice({
  name: "lightsTotal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const { data, regions } = action.payload;
        const model = getModel();

        const structure = data.reduce((prev, item) => {
          const code = item.dims[model.dimId].code;
          const col = item.dims[model.dimId];
          const regionId = item.id;
          const lightId = item.dims[model.dimId]
            .parentId[0] as keyof typeof LIGHTS_MAP_TYPES;

          if (!prev[lightId]) {
            prev[lightId] = {};
          }

          if (!prev[lightId][item.id]) {
            prev[lightId][item.id] = {
              region: getRegion(regionId, regions),
            } as ILightTableRow;
          }

          prev[lightId][item.id][code] = {
            id: col.id,
            code: col.code,
            level: col.level,
            value: col.indicatorVals[model.sumIndicatorId]?.sum,
            attributeVals: prepareAttributes(col.attributeVals),
          };

          return prev;
        }, {} as { [lightId: string]: { [regionId: string]: ILightTableRow } });

        state.data = Object.keys(structure).reduce((prev, lightId) => {
          const lightType =
            LIGHTS_MAP_TYPES[lightId as keyof typeof LIGHTS_MAP_TYPES];
          let rows = Object.values(structure[lightId]) as ILightTableRow[];
          const sortFactor = LIGHT_FIELD_DYNAMICS_SORT[lightType] ?? 1;

          rows = rows
            .filter((row) => row.region.id !== REGION_COUNTRY_CODE)
            .map(
              (row) =>
                ({
                  ...row,
                  rate: LIGHT_RATE_FUNCS[lightType]?.(row, rows),
                } as ILightTableRow)
            )
            .sort(
              (a, b) =>
                sortFactor * b[LIGHT_FIELD_DYNAMICS[lightType]].value -
                sortFactor * a[LIGHT_FIELD_DYNAMICS[lightType]].value
            )
            // Расставим правильные рейтинговые позиции
            .map(
              (row, index) =>
                ({
                  ...row,
                  sn: index + 1,
                } as ILightTableRow)
            );

          prev[lightId] = rows;

          return prev;
        }, {} as { [lightId: string]: ILightTableRow[] });
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
