import {
  IIndicatorAttributeVals,
  IPreparedIndicatorAttributeVals,
  IPreparedLightIndicator,
  IPreparedOlapResponseDimensionItemDim,
} from "../../../core/scheme/olap";

export const prepareUnit = (val: string) =>
  val.replace(/(кв\.\s*м\.|м2)+/, "м²");

export const prepareAttributes = (attributes: IIndicatorAttributeVals) =>
  (Object.keys(attributes) as (keyof IIndicatorAttributeVals)[]).reduce(
    (prev, attr) => {
      const value = attributes[attr];

      switch (attr) {
        case "UNIT":
          prev.UNIT = prepareUnit(value);
          break;
        case "SORT_ORDER":
          prev.SORT_ORDER = parseInt(value) || 0;
          break;
        default:
          prev[attr] = value;
          break;
      }

      return prev;
    },
    {} as IPreparedIndicatorAttributeVals
  );

export const calcPlanFactSegments = (
  plan: number,
  fact: number,
  prev?: number
) => {
  const prevValue = prev ?? fact;
  const minValue = Math.min(fact, plan, prevValue);
  const maxValue = Math.max(fact, plan, prevValue);
  const currentValue = fact >= plan ? maxValue : Math.max(fact, prevValue);

  return [minValue, maxValue, currentValue];
};

export const calcDynamicPercent = (val: number, dyn: number) =>
  Math.round((dyn / val) * 1000) / 10;

export const calcPrevByDynamic = (fact: number, dyn: number) =>
  Math.round((fact - (fact * dyn) / 100) * 10) / 10;

export const getRegion = (
  regionId: string,
  regions: IPreparedOlapResponseDimensionItemDim[]
) => {
  const region: IPreparedLightIndicator = {
    id: regionId,
    code: regionId,
    value: parseInt(regionId),
    attributeVals:
      regions.find((r) => r.id === regionId)?.attributeVals ??
      ({} as IPreparedIndicatorAttributeVals),
  };

  return region;
};
