import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosBasicCredentials } from "axios";

import { getToken } from "./api/client";

export interface ITokenState {
  access_token?: string;
  refresh_token?: string;
  token_type?: string;
  fetching: boolean;
}

const tokenInfoJSON = localStorage.getItem("tokenInfo");
const tokenInfo = tokenInfoJSON ? JSON.parse(tokenInfoJSON) : {};

export const initialState: ITokenState = {
  access_token: tokenInfo.access_token,
  refresh_token: tokenInfo.refresh_token,
  token_type: tokenInfo.token_type,
  fetching: false,
};

export const extraActions = {
  getToken: createAsyncThunk(
    "auth/token/get",
    async (credentials?: AxiosBasicCredentials) => await getToken(credentials)
  ),
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.getToken.pending, (state) => {
        state.fetching = true;
      })
      .addCase(extraActions.getToken.fulfilled, (state, action) => {
        const { access_token, refresh_token, token_type } = action.payload.data;

        state.access_token = access_token;
        state.refresh_token = refresh_token;
        state.token_type = token_type;

        localStorage.setItem(
          "tokenInfo",
          JSON.stringify({
            access_token,
            refresh_token,
            token_type,
          })
        );

        state.fetching = false;
      })
      .addCase(extraActions.getToken.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
