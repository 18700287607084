import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getModel,
  ILightData,
  IPeriodAttributeVals,
} from "../../../core/scheme/olap";
import { getLightsTotals } from "./api/client";
import { prepareAttributes } from "./utils";

export interface ILightsTotalState {
  data?: ILightData;
  fetching: boolean;
}

export const initialState: ILightsTotalState = {
  data: undefined,
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk(
    "lights/total",
    async ({ date, ids }: { date: string; ids: string[] }) =>
      await getLightsTotals({ periodIds: [date], ids })
  ),
};

const slice = createSlice({
  name: "lightsTotal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const model = getModel();

        state.data = action.payload.reduce((prev, row) => {
          const date = (
            row.dims[model.dataPeriodId].attributeVals as IPeriodAttributeVals
          ).REPORT_DATE;

          const item = row.dims[model.dimId];

          if (!prev[item.parentId[0]]) {
            prev[item.parentId[0]] = {};
          }

          prev[item.parentId[0]][item.code] = {
            id: item.id,
            code: item.code,
            date,
            level: item.level,
            value: item.indicatorVals[model.sumIndicatorId]?.sum,
            attributeVals: prepareAttributes(
              item.attributeVals
            ) as ILightData[0][0]["attributeVals"], // TODO
          };
          return prev;
        }, {} as ILightData);

        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
