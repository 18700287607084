import { Radio, RadioProps } from "antd";
import { RadioButtonProps } from "antd/lib/radio/radioButton";

export type ButtonGroupProps = RadioProps & {
  buttons: RadioButtonProps[];
};

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttons,
  ...props
}) => (
  <Radio.Group optionType="button" buttonStyle="solid" {...props}>
    {buttons.map((p) => (
      <Radio.Button key={p.value} {...p} />
    ))}
  </Radio.Group>
);
