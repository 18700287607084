import { FC } from "react";

import * as styles from "./Header.module.scss";
import { HeaderFilters } from "./HeaderFilters";
import logo from "./logo.png";

export const Header: FC = () => {
  return (
    <div className={styles.header}>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={logo} alt="logo" />
        <div className={styles.title}>
          <div className={styles.upperTitle}>
            Государственная автоматизированная информационная система
            "Управление"
          </div>
          <div className={styles.lowerTitle}>
            Мониторинг показателей, достигнутых субъектами Российской Федерации
          </div>
        </div>
      </div>
      <div className={styles.filters}>
        <HeaderFilters />
      </div>
    </div>
  );
};
