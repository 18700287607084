import { Breadcrumb as AntBreadcrumb } from "antd";
import { Route } from "antd/es/breadcrumb/Breadcrumb";
import AntdLink from "antd/lib/typography/Link";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router";

import { useRouter } from "../../hooks/useRouter";
import { useAppSelector } from "../../redux/hooks";
import * as styles from "./Breadcrumbs.module.scss";

export const Breadcrumbs = () => {
  const { navigate } = useRouter();
  const params = useParams<"section" | "id">();
  const lights = useAppSelector((state) => state.lights.list.items);
  const regions = useAppSelector((state) => state.regions.regions.items);

  const onClick = useCallback(
    (path: string) =>
      (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(path);
      },
    [navigate]
  );

  const routes = useMemo(() => {
    const getName = (section?: string, id?: string) => {
      switch (section) {
        case "projects":
          return (
            lights.find((item) => item.id === id)?.attributeVals.Наименование ??
            ""
          );
        case "regions":
          return (
            regions?.find((item) => item.id === id)?.attributeVals
              .Наименование ?? ""
          );
        default:
          return "";
      }
    };

    return [
      {
        path: "/",
        breadcrumbName: "Главная",
      },
      {
        path: `/${params.section}/${params.id}`,
        breadcrumbName: getName(params.section, params.id),
      },
    ];
  }, [lights, params, regions]);

  const itemRender = useMemo(
    () => (route: Route, params: any, routes: Route[], paths: string[]) => {
      const last = routes.indexOf(route) === routes.length - 1;
      return last ? (
        <span>{route.breadcrumbName}</span>
      ) : (
        <AntdLink href={route.path} onClick={onClick(route.path)}>
          {route.breadcrumbName}
        </AntdLink>
      );
    },
    [onClick]
  );

  return (
    <div className={styles.breadcrumbs}>
      <AntBreadcrumb separator="-" itemRender={itemRender} routes={routes} />
    </div>
  );
};
