import { TreeSelect, TreeSelectProps } from "antd";
import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router";

import { IPreparedOlapResponseDimensionItemDim } from "../../../core/scheme/olap";
import { useRouter } from "../../../hooks/useRouter";
import { useRegions } from "../../../redux/slices/lights/hooks/useRegions";
import * as styles from "./FilterRegions.module.scss";

export const FilterRegions: FC = () => {
  const { navigate } = useRouter();
  const { section, id } = useParams<"section" | "id">();
  const { country, districts, regions } = useRegions();

  const treeData = useMemo(() => {
    const regionsMap = regions.reduce((prev, region) => {
      const districtCode = region.parentId[0];

      if (!districtCode) {
        return prev;
      }

      if (!prev[districtCode]) {
        prev[districtCode] = [];
      }

      prev[districtCode].push(region);

      return prev;
    }, {} as { [districtCode: string]: IPreparedOlapResponseDimensionItemDim[] });

    return [
      {
        value: country?.code,
        title: country?.attributeVals.Наименование,
        children: districts.map((district) => ({
          value: district.code,
          title: district.attributeVals.Наименование,
          disabled: true,
          children: regionsMap[district.code]?.map((region) => ({
            value: region.code,
            title: region.attributeVals.Наименование,
          })),
        })),
      },
    ] as TreeSelectProps<string>["treeData"];
  }, [country?.attributeVals.Наименование, country?.code, districts, regions]);

  const value = useMemo(
    () => (section === "regions" ? id : country?.code),
    [country?.code, id, section]
  );

  const handleChange = useCallback(
    (regionCode: string) => {
      if (regionCode === country?.code) {
        navigate("/");
      } else {
        navigate(`/regions/${regionCode}`);
      }
    },
    [country?.code, navigate]
  );

  return (
    <TreeSelect
      className={styles.field}
      treeData={treeData}
      treeNodeFilterProp="title"
      onChange={handleChange}
      value={value}
      showSearch
    />
  );
};
