import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.sharedConstruction;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummarySharedConstruction: FC<ICardSubjectSummaryProps> =
  ({ id }) => {
    const { data, total, fetching } = useCardSubjectSummary(id, LIGHT_ID);

    if (fetching) {
      return (
        <div className={styles.loader}>
          <Spin />
        </div>
      );
    }

    return (
      <>
        <span className={styles.title}>
          Сведения предоставлены на{" "}
          {data?.curr["123"]
            ? moment(data.curr["123"].date).format(DATE_DISPLAY_FORMAT)
            : ""}
        </span>
        <div className={styles.cardsInfoWrapper}>
          <CardInfo
            value={total?.sn}
            description="Позиция субъекта в рейтингах"
            rate={total?.rate}
          />
          <CardInfo
            value={
              data?.curr["123"]
                ? formatNumber(data.curr["123"]?.value, undefined, 0)
                : "-"
            }
            description="Проблемных объектов на начало года"
          />
          <CardInfo
            value={
              data?.curr["124"]
                ? formatNumber(data.curr["124"]?.value, undefined, 0)
                : "-"
            }
            description="Проблемных объектов"
          />
          <CardInfo
            value={
              data?.curr["126"]
                ? formatNumber(data.curr["126"]?.value, undefined, 1)
                : "-"
            }
            description="Прирост проблемных объектов"
            percent
          />
          <CardInfo
            value={
              data?.curr["118"]
                ? formatNumber(data.curr["118"]?.value, undefined, 1)
                : "-"
            }
            description="тыс. м&sup2; в Едином реестре проблемных объектов"
          />
        </div>
        <div>
          <div className={clsx(styles.heading, styles.marginTop48)}>
            Открыто конкурсное производство
          </div>
          <div className={styles.flex}>
            <div className={styles.width33}>
              <div className={styles.count}>
                {data?.curr["162"]
                  ? formatNumber(data.curr["162"]?.value, undefined, 0)
                  : "-"}
              </div>
              <span className={styles.info}>Количество застройщиков</span>
            </div>
            <div className={styles.width33}>
              <div className={styles.count}>
                {data?.curr["166"]
                  ? formatNumber(data.curr["166"]?.value, undefined, 0)
                  : "-"}
              </div>
              <span className={styles.info}>Количество домов</span>
            </div>
            <div className={styles.width33}>
              <div className={styles.count}>
                {data?.curr["170"]
                  ? formatNumber(data.curr["170"]?.value, undefined, 0)
                  : "-"}
              </div>
              <span className={styles.info}> Жилая площадь, тыс. кв. м.</span>
            </div>
          </div>

          <div className={clsx(styles.heading, styles.marginTop48)}>
            Нарушены сроки завершения строительства более чем на 6 месяцев
          </div>
          <div className={styles.flex}>
            <div className={styles.width33}>
              <div className={styles.count}>
                {data?.curr["164"]
                  ? formatNumber(data.curr["164"]?.value, undefined, 0)
                  : "-"}
              </div>
              <span className={styles.info}>Количество застройщиков</span>
            </div>
            <div className={styles.width33}>
              <div className={styles.count}>
                {data?.curr["168"]
                  ? formatNumber(data.curr["168"]?.value, undefined, 0)
                  : "-"}
              </div>
              <span className={styles.info}>Количество домов</span>
            </div>
            <div className={styles.width33}>
              <div className={styles.count}>
                {data?.curr["172"]
                  ? formatNumber(data.curr["172"]?.value, undefined, 0)
                  : "-"}
              </div>
              <span className={styles.info}>Жилая площадь, тыс. кв. м.</span>
            </div>
          </div>

          <div className={styles.subHeader}>
            <div className={styles.count}>Механизмы</div>
            <div className={clsx(styles.flex, styles.marginTop48)}>
              <div className={clsx(styles.width50, styles.heading)}>
                Механизмы Фонда защиты прав граждан
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {formatNumber(
                        (data?.curr["174"]?.value ?? 0) +
                          (data?.curr["175"]?.value ?? 0) +
                          (data?.curr["176"]?.value ?? 0),
                        undefined,
                        0
                      )}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {formatNumber(
                        (data?.curr["181"]?.value ?? 0) +
                          (data?.curr["182"]?.value ?? 0) +
                          (data?.curr["183"]?.value ?? 0),
                        undefined,
                        0
                      )}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м
                    </span>
                  </div>
                </div>
              </div>

              <div className={clsx(styles.width50, styles.heading)}>
                Механизмы субъекта РФ
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {formatNumber(
                        (data?.curr["177"]?.value ?? 0) +
                          (data?.curr["178"]?.value ?? 0) +
                          (data?.curr["179"]?.value ?? 0) +
                          (data?.curr["180"]?.value ?? 0),
                        undefined,
                        0
                      )}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {formatNumber(
                        (data?.curr["184"]?.value ?? 0) +
                          (data?.curr["185"]?.value ?? 0) +
                          (data?.curr["186"]?.value ?? 0) +
                          (data?.curr["187"]?.value ?? 0),
                        undefined,
                        0
                      )}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.subHeader}>
            <div className={styles.count}>Инвестиции</div>
            <div className={clsx(styles.flex, styles.marginTop48)}>
              <div className={clsx(styles.width50, styles.heading)}>
                Привлечение инвестора
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["177"]
                        ? formatNumber(data.curr["177"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["184"]
                        ? formatNumber(data.curr["184"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м
                    </span>
                  </div>
                </div>
              </div>

              <div className={clsx(styles.width50, styles.heading)}>
                Восстановление прав за счет средств или имущества РФ
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["178"]
                        ? formatNumber(data.curr["178"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["185"]
                        ? formatNumber(data.curr["185"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={clsx(styles.flex, styles.marginTop48)}>
              <div className={clsx(styles.width50, styles.heading)}>
                Механизмы Фонда защиты прав граждан
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["179"]
                        ? formatNumber(data.curr["179"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["186"]
                        ? formatNumber(data.curr["186"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м
                    </span>
                  </div>
                </div>
              </div>

              <div className={clsx(styles.width50, styles.heading)}>
                Механизмы субъекта РФ
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["180"]
                        ? formatNumber(data.curr["180"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}> Количество домов </span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["187"]
                        ? formatNumber(data.curr["187"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.marginTop48}>
            <div className={styles.count}>Решения</div>
            <div className={clsx(styles.flex, styles.marginTop48)}>
              <div className={clsx(styles.width33, styles.heading)}>
                Принято решение о выплате компенсаций
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["174"]
                        ? formatNumber(data.curr["174"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["181"]
                        ? formatNumber(data.curr["181"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м.
                    </span>
                  </div>
                </div>
              </div>

              <div className={clsx(styles.width33, styles.heading)}>
                Принято решение о завершении строительства
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["175"]
                        ? formatNumber(data.curr["175"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["182"]
                        ? formatNumber(data.curr["182"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м.
                    </span>
                  </div>
                </div>
              </div>

              <div className={clsx(styles.width33, styles.heading)}>
                На рассмотрении
                <div className={styles.flex}>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["176"]
                        ? formatNumber(data.curr["176"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>Количество домов</span>
                  </div>
                  <div className={styles.width50}>
                    <div className={styles.count}>
                      {data?.curr["183"]
                        ? formatNumber(data.curr["183"]?.value, undefined, 0)
                        : "-"}
                    </div>
                    <span className={styles.info}>
                      Жилая площадь, тыс. кв. м.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
