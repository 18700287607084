import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getModel, ILightData, ILightRow } from "../../../core/scheme/olap";
import { REGION_COUNTRY_CODE } from "../regions/api/scheme";
import { getLightDynamics } from "./api/client";
import { prepareAttributes } from "./utils";

export interface ILightsDynamicsState {
  data?: ILightData;
  additionalData?: ILightData;
  fetching: boolean;
}

export const initialState: ILightsDynamicsState = {
  data: undefined,
  additionalData: undefined,
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk(
    "lights/dynamics",
    async ({
      ids,
      additionalIds,
      date,
    }: {
      ids: string[];
      additionalIds: string[];
      date: string;
      additionalId?: string;
    }) =>
      await getLightDynamics({
        parentIds:
          additionalIds && additionalIds.length > 0
            ? [...ids, ...additionalIds]
            : ids,
        regionIds: [REGION_COUNTRY_CODE],
        regionLevels: [0, 2],
        periodIds: [date],
      })
  ),
};

const slice = createSlice({
  name: "lightsDynamics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.data = undefined;
        state.additionalData = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const model = getModel();

        const payload = action.payload.reduce((prev, item) => {
          const projectId = item.dims[model.dimId].parentId[0];

          if (!prev[projectId]) {
            prev[projectId] = {};
          }

          if (!prev[projectId][item.code]) {
            prev[projectId][item.code] = {};
          }

          prev[projectId][item.code][item.dims[model.dimId].code] = {
            id: item.dims[model.dimId].id,
            code: item.dims[model.dimId].code,
            level: item.dims[model.dimId].level,
            projectId,
            value:
              item.dims[model.dimId].indicatorVals[model.sumIndicatorId]?.sum,
            attributeVals: prepareAttributes(
              item.dims[model.dimId].attributeVals
            ),
          };
          return prev;
        }, {} as Record<string, ILightData>);

        const data = action.meta.arg.ids.reduce((prev, id) => {
          if (!payload[id]) {
            return prev;
          }

          Object.keys(payload[id]).forEach((regionId) => {
            prev[regionId] = {
              ...(prev[regionId] ?? {}),
              ...payload[id][regionId],
            } as ILightRow;
          });
          return prev;
        }, {} as ILightData);

        const additionalData = action.meta.arg.additionalIds.reduce(
          (prev, id) => {
            Object.keys(payload[id] ?? {}).forEach((regionId) => {
              prev[regionId] = {
                ...(prev[regionId] ?? {}),
                ...payload[id][regionId],
              } as ILightRow;
            });
            return prev;
          },
          {} as ILightData
        );

        return {
          ...state,
          data,
          additionalData,
          fetching: false,
        };
      })
      .addCase(extraActions.get.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
