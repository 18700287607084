import { Card } from "antd";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import { useRouter } from "../../hooks/useRouter";
import { formatNumber } from "../../lib/number";
import { LIGHT_RATES } from "../../redux/slices/lights/api/rate/rate";
import {
  DATE_DEFAULT_FORMAT,
  DATE_DISPLAY_FORMAT,
  LIGHTS_TYPES,
} from "../../redux/slices/lights/api/scheme";
import {
  LIGHT_FACT,
  LIGHT_FIELD_DYNAMICS,
  LIGHT_PLAN,
  useLight,
} from "../../redux/slices/lights/hooks/useLight";
import { CardHeader } from "../CardHeader/CardHeader";
import { CardSubStatsContent } from "../CardSubStatsContent/CardSubStatsContent";
import { Empty } from "../Empty";
import { PlanFactProgressBar } from "../PlanFactProgressBar/PlanFactProgressBar";

type PropsType = {
  big: boolean;
  id: string;
};

export const CardMortgageMetrics: React.FC<PropsType> = ({ big, id }) => {
  const { getPath } = useRouter();
  const { total } = useLight(id);

  const dynamics = total?.[LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgage]]?.value;

  const rate = useMemo(() => {
    if (!dynamics) {
      return undefined;
    }

    return dynamics > 0
      ? LIGHT_RATES.high
      : dynamics < 0
      ? LIGHT_RATES.low
      : undefined;
  }, [dynamics]);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation();
    },
    []
  );

  return (
    <Card
      bordered={false}
      title={
        <CardHeader
          big={big}
          title="Прирост выданных ИК"
          subTitle="Ипотека"
          helperText={
            total
              ? `Данные на ${moment(total["106"]?.date).format(
                  DATE_DISPLAY_FORMAT
                )}`
              : ""
          }
        />
      }
    >
      {total ? (
        <>
          <PlanFactProgressBar
            plan={LIGHT_PLAN[LIGHTS_TYPES.mortgage](total)?.value}
            fact={LIGHT_FACT[LIGHTS_TYPES.mortgage](total)?.value}
            prev={total["106"]?.value ?? 0}
            dynamics={dynamics}
            rate={rate}
            unit={LIGHT_PLAN[LIGHTS_TYPES.mortgage](total)?.attributeVals.UNIT}
            nextLabel={moment(total["106"]?.date).format("YYYY")}
            prevLabel={moment(total["106"]?.date)
              .subtract(1, "year")
              .format("YYYY")}
          />
          <CardSubStatsContent
            title="Объем ипотечных кредитов"
            titleRight={` · ${total["108"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["108"]?.value || 0, undefined, 0),
                footerText: `на ${moment(total["108"]?.date).format(
                  DATE_DEFAULT_FORMAT
                )}`,
                valueSize: big ? "big" : "small",
              },
              {
                value: formatNumber(total["109"]?.value || 0, undefined, 0),
                footerText: `на ${moment(total["109"]?.date)
                  .subtract(1, "year")
                  .format(DATE_DEFAULT_FORMAT)}`,
                valueSize: big ? "big" : "small",
              },
            ]}
          />
          <CardSubStatsContent
            title="Средняя ставка по ИК"
            titleRight={` · ${total["110"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["110"]?.value || 0, undefined, 2),
                footerText: `на ${moment(total["110"]?.date).format(
                  DATE_DEFAULT_FORMAT
                )}`,
                extraValue: total["112"]?.value,
                extraRate:
                  total["112"]?.value < 0 ? LIGHT_RATES.low : LIGHT_RATES.high,
                valueSize: big ? "big" : "small",
              },
              {
                value: formatNumber(total["111"]?.value || 0, undefined, 2),
                footerText: `на ${moment(total["111"]?.date)
                  .subtract(1, "year")
                  .format(DATE_DEFAULT_FORMAT)}`,
                valueSize: big ? "big" : "small",
              },
            ]}
          />
          <CardSubStatsContent
            title="Средняя ставка по ИК выданным под залог ДДУ"
            titleRight={` · ${total["113"]?.attributeVals.UNIT}`}
            sm={!big}
            data={[
              {
                value: formatNumber(total["113"]?.value || 0, undefined, 2),
                footerText: `на ${moment(total["113"]?.date).format(
                  DATE_DEFAULT_FORMAT
                )}`,
                extraValue: total["115"]?.value,
                extraRate:
                  total["115"]?.value < 0 ? LIGHT_RATES.low : LIGHT_RATES.high,
                valueSize: big ? "big" : "small",
              },
              {
                value: formatNumber(total["114"]?.value || 0, undefined, 2),
                footerText: `на ${moment(total["114"]?.date)
                  .subtract(1, "year")
                  .format(DATE_DEFAULT_FORMAT)}`,
                valueSize: big ? "big" : "small",
              },
            ]}
          />
          <div className="cardExtraLinks">
            <div>
              <Link to={getPath(`/projects/160000`)} onClick={onClick}>
                Гос. программы
              </Link>
            </div>
            <div>
              <Link to={getPath(`/projects/200000`)} onClick={onClick}>
                Первичка/Вторичка
              </Link>
            </div>
          </div>
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
