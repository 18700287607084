export const formatNumber = (
  value?: number,
  unit?: string,
  fractionDigits = 2
) => {
  let textValue = "";
  if (typeof value === "number") {
    textValue = new Intl.NumberFormat(navigator.language, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }).format(value);
  } else {
    textValue = "-";
  }

  return unit ? `${textValue} ${unit}` : textValue;
};

export const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);
