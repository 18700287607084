import { Typography } from "antd";
import { FC, useEffect } from "react";
import { useParams } from "react-router";

import { CardSubjectBudget } from "../../components/CardSubjectBudget/CardSubjectBudget";
import { CardSubjectRating } from "../../components/CardSubjectRating/CardSubjectRating";
import { CardSubjectSummary } from "../../components/CardSubjectSummary/CardSubjectSummary";
import { useDate } from "../../hooks/useDate";
import { useAppDispatch } from "../../redux/hooks";
import actions from "../../redux/slices/lights/actions";
import { useRegion } from "../../redux/slices/lights/hooks/useRegion";
import { REGION_COATS } from "../../redux/slices/regions/api/scheme";
import * as styles from "./Region.module.scss";

export const Region: FC = () => {
  const dispatch = useAppDispatch();
  const { date } = useDate();
  const { id = "" } = useParams<string>();
  const region = useRegion(id);

  useEffect(() => {
    if (region) {
      dispatch<any>(actions.dynamicsRegion.get({ date, id: region.id }));
    }
  }, [date, dispatch, region]);

  return region ? (
    <>
      <div className={styles.cardTop}>
        <img
          className={styles.logo}
          src={REGION_COATS[id as keyof typeof REGION_COATS]}
          alt="logo"
        />
        <Typography.Title level={2}>
          {region.attributeVals.Наименование}
        </Typography.Title>
      </div>
      <div className={styles.cardsWrapper}>
        <CardSubjectBudget />
        <CardSubjectRating id={id} />
      </div>
      <div className={styles.cardSubjectSummaryWrapper}>
        <CardSubjectSummary id={id} />
      </div>
    </>
  ) : null;
};
