import { Spin } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";

import { formatNumber } from "../../../lib/number";
import {
  DATE_DISPLAY_FORMAT,
  LIGHTS_MAP,
  LIGHTS_TYPES,
} from "../../../redux/slices/lights/api/scheme";
import * as styles from "../CardSubjectSummary.module.scss";
import { CardInfo } from "../components/CardInfo";
import { useCardSubjectSummary } from "../hooks/useCardSubjectSummary";
import { ICardSubjectSummaryProps } from "../scheme";

const LIGHT_TYPE = LIGHTS_TYPES.project;
const LIGHT_ID = LIGHTS_MAP[LIGHT_TYPE];

export const CardSubjectSummaryProject: FC<ICardSubjectSummaryProps> = ({
  id,
}) => {
  const { data, district, districtRate, total, totals, fetching } =
    useCardSubjectSummary(id, LIGHT_ID);

  if (fetching) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <span className={styles.title}>
        Сведения предоставлены на{" "}
        {data?.curr["67"]
          ? moment(data.curr["67"].date).format(DATE_DISPLAY_FORMAT)
          : ""}
      </span>
      <div className={styles.cardsInfoWrapper}>
        <CardInfo
          value={total?.sn}
          description="Позиция субъекта в рейтингах"
          rate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["67"]
              ? formatNumber(data.curr["67"].value, undefined, 1)
              : "-"
          }
          description={`${data?.curr["67"]?.attributeVals.UNIT ?? ""}, ЛБО`}
        />
        <CardInfo
          value={
            data?.curr["70"]
              ? formatNumber(data.curr["70"].value, undefined, 1)
              : "-"
          }
          description={`${
            data?.curr["70"]?.attributeVals.UNIT ?? ""
          }, кассовое исполнение`}
        />
        <CardInfo
          value={
            data?.curr["71"]
              ? formatNumber(
                  data.curr["71"].value,
                  data.curr["71"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% кассового исполнения"
          subRate={total?.rate}
        />
        <CardInfo
          value={
            data?.curr["69"]
              ? formatNumber(
                  data.curr["69"].value,
                  data.curr["69"].attributeVals.UNIT,
                  1
                )
              : "-"
          }
          description="% законтрактованности"
        />
      </div>
      <div>
        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Общее количество проектов, шт.
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["74"]
                    ? formatNumber(data.curr["74"]?.value, undefined, 0)
                    : "-"}
                </div>
                <span className={styles.info}>
                  {moment(data?.curr["74"]?.date).format("YYYY")}
                </span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {data?.curr["72"]
                    ? formatNumber(data.curr["72"]?.value, undefined, 0)
                    : "-"}
                </div>
                <span className={styles.info}>
                  {moment(data?.curr["72"]?.date)
                    .subtract(1, "year")
                    .format("YYYY")}
                </span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Кассовое исполнение,{" "}
            {totals?.[LIGHT_ID]?.["71"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["71"]
                    ? formatNumber(
                        totals[LIGHT_ID]["71"]?.value,
                        totals[LIGHT_ID]["71"]?.attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div
                  className={clsx(
                    styles.count,
                    styles[districtRate as keyof typeof styles]
                  )}
                >
                  {district?.[LIGHT_ID]?.["71"]
                    ? formatNumber(
                        district[LIGHT_ID]["71"].value,
                        district[LIGHT_ID]["71"].attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(styles.flex, styles.marginTop48)}>
          <div className={clsx(styles.width50, styles.heading)}>
            Завершенное количество проектов, шт.
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {/* TODO: посчитать на стороне БД */}-
                </div>
                <span className={styles.info}>
                  {
                    moment(data?.curr["74"]?.date).format(
                      "YYYY"
                    ) /* TODO: заменить показатель */
                  }
                </span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {/* TODO: посчитать на стороне БД */}-
                </div>
                <span className={styles.info}>
                  {
                    moment(data?.curr["72"]?.date)
                      .subtract(1, "year")
                      .format("YYYY") /* TODO: заменить показатель */
                  }
                </span>
              </div>
            </div>
          </div>

          <div className={clsx(styles.width50, styles.heading)}>
            Законтрактованность,{" "}
            {totals?.[LIGHT_ID]?.["69"]?.attributeVals.UNIT}
            <div className={styles.flex}>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {totals?.[LIGHT_ID]?.["69"]
                    ? formatNumber(
                        totals[LIGHT_ID]["69"]?.value,
                        totals[LIGHT_ID]["69"].attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>РФ</span>
              </div>
              <div className={styles.width50}>
                <div className={styles.count}>
                  {district?.[LIGHT_ID]?.["69"]
                    ? formatNumber(
                        district[LIGHT_ID]["69"].value,
                        district[LIGHT_ID]["69"].attributeVals.UNIT,
                        1
                      )
                    : "-"}
                </div>
                <span className={styles.info}>Федеральный округ</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
