import { ILightRow } from "../../../../../core/scheme/olap";
import { REGION_COUNTRY_CODE } from "../../../regions/api/scheme";
import { LIGHT_FIELD_DYNAMICS } from "../../hooks/useLight";
import { LIGHTS_TYPES } from "../scheme";
import { LIGHT_RATES } from "./rate";

export const getItemRate = (item: ILightRow, rows: ILightRow[]) => {
  let rate: LIGHT_RATES | undefined = undefined;

  const total = rows.find((r) => r.region.code === REGION_COUNTRY_CODE);

  if (!total || rows.length < 1) {
    return rate;
  }

  const dynamicsField = LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgage];
  const dynamicsIndicator = item[dynamicsField];

  if (dynamicsIndicator && item.region.id !== total.region.id) {
    const totalDynamics = total[dynamicsField]?.value ?? 0;
    const minDynamics = rows
      .filter((r) => r.region.code !== REGION_COUNTRY_CODE)
      .sort((a, b) => a[dynamicsField].value - b[dynamicsField].value)[0][
      dynamicsField
    ].value;
    const minHalf = Math.ceil(((totalDynamics + minDynamics) * 10) / 2) / 10;

    const dynamics = dynamicsIndicator.value ?? 0;

    if (dynamics > totalDynamics) {
      rate = LIGHT_RATES.high;
    } else if (dynamics < minHalf) {
      rate = LIGHT_RATES.low;
    } else {
      rate = LIGHT_RATES.middle;
    }
  }

  return rate;
};

export default getItemRate;
