import axios from "axios";

const instance = axios.create({
  withCredentials: true,
});

export const setAuthHeader = (access_token: string) => {
  instance.defaults.headers.Authorization = `Bearer ${access_token}`;
};

export default instance;
