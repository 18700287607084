export const REGION_COUNTRY_CODE = "8";
export const REGION_DISTRICT_CODES = ["9", "25", "34", "39", "45", "56", "71"];

export const REGION_COATS = {
  //"8": "Российская Федерация",
  //"9": "Центральный федеральный округ",
  "10": "/regions/Coat_of_Arms_of_Belgorod_Oblast.svg",
  "11": "/regions/Coat_of_Arms_of_Bryansk_Oblast.svg",
  "12": "/regions/Coat_of_arms_of_Vladimiri_Oblast.svg",
  "13": "/regions/Coat_of_arms_of_Voronezh_Oblast.svg",
  "14": "/regions/Coat_of_Arms_of_Ivanovo_Oblast.svg",
  "15": "/regions/Coat_of_arms_of_Kaluga_Oblast.svg",
  "16": "/regions/Coat_of_arms_of_Kostroma_Oblast.svg",
  "17": "/regions/Coat_of_Arms_of_Kursk_oblast.svg",
  "18": "/regions/Coat_of_Arms_of_Lipetsk_oblast.svg",
  "19": "/regions/Coat_of_arms_of_Moscow_Oblast_(large).svg",
  "20": "/regions/Coat_of_Arms_of_Ryazan_oblast.png",
  "21": "/regions/Coat_of_arms_of_Smolensk_oblast.svg",
  "22": "/regions/Coat_of_Arms_of_Tver_oblast.svg",
  "23": "/regions/Coat_of_Arms_of_Tula_oblast.png",
  "24": "/regions/Coat_of_arms_of_Yaroslavl_Oblast.svg",
  //"25": "/regions/Северо-Западный федеральный округ",
  "26": "/regions/Coat_of_Arms_of_Republic_of_Karelia.svg",
  "27": "/regions/Coat_of_Arms_of_the_Komi_Republic.svg",
  "28": "/regions/Coat_of_arms_of_Nenets_Autonomous_Okrug.svg",
  "29": "/regions/Coat_of_arms_of_Vologda_oblast.svg",
  "30": "/regions/Coat_of_arms_of_Leningrad_Oblast.svg",
  "31": "/regions/Герб_Мурманской_области.svg",
  "32": "/regions/Coat_of_arms_of_Pskov_Oblast_(2018).svg",
  "33": "/regions/Coat_of_Arms_of_Saint_Petersburg_(2003).svg",
  //"34": "/regions/Южный федеральный округ",
  "35": "/regions/Coat_of_arms_of_Adygea.svg",
  "36": "/regions/Coat_of_Arms_of_Krasnodar_Kray.svg",
  "37": "/regions/Coat_of_Arms_of_Astrakhan_Oblast.svg",
  "38": "/regions/Coat_of_arms_of_Rostov_Oblast.svg",
  //"39": "/regions/Северо-Кавказский федеральный округ",
  "40": "/regions/Coat_of_Arms_of_Dagestan.svg",
  "41": "/regions/Coat_of_Arms_of_Kabardino-Balkaria.svg",
  "42": "/regions/Coat_of_Arms_of_Karachay-Cherkessia.svg",
  "43": "/regions/Coat_of_arms_of_Chechnya.svg",
  "44": "/regions/Coat_of_arms_of_Stavropol_Krai.svg",
  //"45": "/regions/Приволжский федеральный округ",
  "46": "/regions/Coat_of_Arms_of_Bashkortostan.svg",
  "47": "/regions/Coat_of_Arms_of_Mordovia.svg",
  "48": "/regions/Coat_of_Arms_of_Tatarstan.svg",
  "49": "/regions/Coat_of_arms_of_Udmurtia.svg",
  "50": "/regions/Coat_of_Arms_of_Perm_Krai.svg",
  "51": "/regions/Coat_of_arms_of_Kirov_Region.svg",
  "52": "/regions/Coat_of_arms_of_Orenburg_Oblast.svg",
  "53": "/regions/Coat_of_arms_of_Penza_Oblast.svg",
  "54": "/regions/Coat_of_arms_of_Samara_Oblast.svg",
  "55": "/regions/Герб_Ульяновской_области_(2013).svg",
  //"56": "/regions/Уральский федеральный округ",
  "57": "/regions/Coat_of_arms_of_Kurgan_Oblast.svg",
  "58": "/regions/Coat_of_arms_of_Tyumen_Oblast.png",
  "59": "/regions/Coat_of_arms_of_Yugra_(Khanty-Mansia).svg",
  "60": "/regions/Coat_of_Arms_of_Yamal_Nenetsia.svg",
  //"61": "/regions/Сибирский федеральный округ",
  "62": "/regions/Coat_of_Arms_of_Altai_Republic.svg",
  "64": "/regions/Coat_of_arms_of_Khakassia.svg",
  "65": "/regions/Coat_of_Arms_of_Altai_Krai.svg",
  "66": "/regions/Coat_of_arms_of_Krasnoyarsk_Krai.svg",
  "67": "/regions/Coat_of_arms_of_Irkutsk_Oblast.svg",
  "68": "/regions/Coat_of_arms_of_Novosibirsk_oblast.svg",
  "69": "/regions/Wappen_von_Oblast_Omsk_(seit_2020).png",
  "70": "/regions/Coat_of_arms_of_Tomsk_Oblast.svg",
  //"71": "/regions/Дальневосточный федеральный округ",
  "72": "/regions/Coat_of_Arms_of_Kamchatka_Krai.svg",
  "73": "/regions/Coat_of_arms_of_Primorsky_Krai.svg",
  "74": "/regions/Coat_of_arms_of_Amur_Oblast.svg",
  "75": "/regions/Coat_of_Arms_of_Magadan_oblast.svg",
  "76": "/regions/Coat_of_arms_of_the_Jewish_Autonomous_Oblast.svg",
  "77": "/regions/Coat_of_Arms_of_Chukotka.svg",
  "94": "/regions/COA_of_Sevastopol.svg",
  "95": "/regions/Emblem_of_Crimea.svg",
  //"96": "/regions/Не определено",
  //"97": "/regions/г. Байконур",
  "101": "/regions/Coat_of_arms_of_Oryol_oblast2012.png",
  "102": "/regions/Coat_of_arms_of_Tambov_Oblast.svg",
  "103": "/regions/Coat_of_Arms_of_Moscow.svg",
  "104": "/regions/Coat_of_Arms_of_Arkhangelsk_oblast.svg",
  "105": "/regions/Coat_of_Arms_of_Kaliningrad_Oblast.svg",
  "106": "/regions/Coat_of_arms_of_Novgorod_Oblast.svg",
  "107": "/regions/Coat_of_Arms_of_Kalmykia.svg",
  "108": "/regions/Coat_of_Arms_of_Volgograd_oblast.svg",
  "109": "/regions/Coat_of_Arms_of_Ingushetia.svg",
  "110": "/regions/Wapen_Ossetien.svg",
  "111": "/regions/Coat_of_Arms_of_Mari_El.svg",
  "112": "/regions/Coat_of_Arms_of_Chuvashia.svg",
  "113": "/regions/Coat_of_arms_of_Nizhny_Novgorod_Region.svg",
  "114": "/regions/Coat_of_Arms_of_Saratov_oblast.svg",
  "115": "/regions/Coat_of_Arms_of_Sverdlovsk_oblast.svg",
  "116": "/regions/Coat_of_arms_of_Chelyabinsk_Oblast.svg",
  "117": "/regions/Coat_of_arms_of_Tuva.svg",
  "119": "/regions/Coat_of_arms_of_Kemerovo_Oblast.svg",
  "120": "/regions/Coat_of_Arms_of_Sakha_(Yakutia).svg",
  "121": "/regions/Coat_of_arms_of_Krasnoyarsk_Krai.svg",
  "123": "/regions/Coat_of_Arms_of_Buryatia.svg",
  "124": "/regions/Coat_of_arms_of_Zabaykalsky_Krai.svg",
  "122": "/regions/Sakhalin_Oblast_Coat_of_Arms.svg",
};
