import { TreeSelect } from "antd";
import { FC } from "react";

import { useDate } from "../../../hooks/useDate";
import * as styles from "./FilterDate.module.scss";

export const FilterDate: FC = () => {
  const { date, setDate, treeData } = useDate();

  return (
    <TreeSelect
      className={styles.field}
      treeData={treeData}
      treeNodeFilterProp="title"
      onChange={setDate}
      value={date}
      showSearch
    />
  );
};
