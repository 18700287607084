import { useCallback, useMemo } from "react";

import {
  ILightData,
  ILightRow,
  ILightTableRow,
} from "../../../../core/scheme/olap";
import { useAppSelector } from "../../../hooks";
import { REGION_COUNTRY_CODE } from "../../regions/api/scheme";
import { LIGHT_RATE_FUNCS, LIGHT_RATES } from "../api/rate/rate";
import { LIGHTS_MAP, LIGHTS_MAP_TYPES, LIGHTS_TYPES } from "../api/scheme";
import { getRegion } from "../utils";
import { useRegions } from "./useRegions";

export const LIGHT_PLAN = {
  [LIGHTS_TYPES.city]: (row: ILightRow) => row["90"],
  [LIGHTS_TYPES.housing]: (row: ILightRow) => row["8"],
  [LIGHTS_TYPES.mortgage]: (row: ILightRow) =>
    row["105"]?.value > row["106"]?.value ? row["105"] : row["106"],
  [LIGHTS_TYPES.mortgageGov]: (row: ILightRow) => row["192"],
  [LIGHTS_TYPES.mortgageGovBenefits]: (row: ILightRow) => row["195"],
  [LIGHTS_TYPES.mortgageGovFamily]: (row: ILightRow) => row["200"],
  [LIGHTS_TYPES.mortgageFarEast]: (row: ILightRow) => row["205"],
  [LIGHTS_TYPES.mortgageVillage]: (row: ILightRow) => row["273"],
  [LIGHTS_TYPES.mortgagePrimary]: (row: ILightRow) => row["209"],
  [LIGHTS_TYPES.project]: (row: ILightRow) => row["67"],
  [LIGHTS_TYPES.resettlement]: (row: ILightRow) => row["37"],
  [LIGHTS_TYPES.roads]: (row: ILightRow) => row["20"],
  [LIGHTS_TYPES.roadsDead]: (row: ILightRow) => row["256"],
  [LIGHTS_TYPES.sharedConstruction]: (row: ILightRow) => row["120"],
  [LIGHTS_TYPES.stimul]: (row: ILightRow) => row["51"],
  [LIGHTS_TYPES.water]: (row: ILightRow) => row["77"],
  [LIGHTS_TYPES.recoveryVolga]: (row: ILightRow) => row["240"],
  [LIGHTS_TYPES.baikal]: (row: ILightRow) => row["228"],
  [LIGHTS_TYPES.roadsRegional]: (row: ILightRow) => row["129"],
  [LIGHTS_TYPES.roadsInfrastructure]: (row: ILightRow) => row["260"],
};

export const LIGHT_FACT = {
  [LIGHTS_TYPES.city]: (row: ILightRow) => row["91"],
  [LIGHTS_TYPES.housing]: (row: ILightRow) => row["5"],
  [LIGHTS_TYPES.mortgage]: (row: ILightRow) => row["105"],
  [LIGHTS_TYPES.mortgageGov]: (row: ILightRow) => row["191"],
  [LIGHTS_TYPES.mortgageGovBenefits]: (row: ILightRow) => row["196"],
  [LIGHTS_TYPES.mortgageGovFamily]: (row: ILightRow) => row["201"],
  [LIGHTS_TYPES.mortgageFarEast]: (row: ILightRow) => row["206"],
  [LIGHTS_TYPES.mortgageVillage]: (row: ILightRow) => row["274"],
  [LIGHTS_TYPES.mortgagePrimary]: (row: ILightRow) => row["210"],
  [LIGHTS_TYPES.project]: (row: ILightRow) => row["70"],
  [LIGHTS_TYPES.resettlement]: (row: ILightRow) => row["38"],
  [LIGHTS_TYPES.roads]: (row: ILightRow) => row["22"],
  [LIGHTS_TYPES.roadsDead]: (row: ILightRow) => row["257"],
  [LIGHTS_TYPES.roadsRegional]: (row: ILightRow) => row["130"],
  [LIGHTS_TYPES.roadsInfrastructure]: (row: ILightRow) => row["263"],
  [LIGHTS_TYPES.sharedConstruction]: (row: ILightRow) => row["124"],
  [LIGHTS_TYPES.stimul]: (row: ILightRow) => row["54"],
  [LIGHTS_TYPES.water]: (row: ILightRow) => row["78"],
  [LIGHTS_TYPES.recoveryVolga]: (row: ILightRow) => row["241"],
  [LIGHTS_TYPES.baikal]: (row: ILightRow) => row["229"],
};

export const LIGHT_FIELD_DYNAMICS = {
  [LIGHTS_TYPES.city]: "92",
  [LIGHTS_TYPES.housing]: "7",
  [LIGHTS_TYPES.mortgage]: "107",
  [LIGHTS_TYPES.mortgageGov]: "193",
  [LIGHTS_TYPES.mortgageGovBenefits]: "199",
  [LIGHTS_TYPES.mortgageGovFamily]: "204",
  [LIGHTS_TYPES.mortgageFarEast]: "207",
  [LIGHTS_TYPES.mortgageVillage]: "292",
  [LIGHTS_TYPES.mortgagePrimary]: "211",
  [LIGHTS_TYPES.project]: "71",
  [LIGHTS_TYPES.resettlement]: "39",
  [LIGHTS_TYPES.roads]: "23",
  [LIGHTS_TYPES.roadsDead]: "258",
  [LIGHTS_TYPES.roadsRegional]: "131",
  [LIGHTS_TYPES.roadsInfrastructure]: "266",
  [LIGHTS_TYPES.sharedConstruction]: "126",
  [LIGHTS_TYPES.stimul]: "55",
  [LIGHTS_TYPES.water]: "79",
  [LIGHTS_TYPES.recoveryVolga]: "242",
  [LIGHTS_TYPES.baikal]: "230",
};

export const LIGHT_FIELD_DYNAMICS_SORT: Partial<Record<LIGHTS_TYPES, number>> =
  {
    [LIGHTS_TYPES.sharedConstruction]: -1,
  };

export const LIGHT_COLORED_COLS: { [type in LIGHTS_TYPES]?: string[] } = {
  [LIGHTS_TYPES.city]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.city]],
  [LIGHTS_TYPES.housing]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.housing]],
  [LIGHTS_TYPES.mortgage]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgage]],

  // TODO(GASUNP-13241): Временно отключено
  // [LIGHTS_TYPES.mortgageGov]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageGov]],
  // [LIGHTS_TYPES.mortgageGovBenefits]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageGovBenefits],
  // ],
  // [LIGHTS_TYPES.mortgageGovFamily]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageGovFamily],
  // ],
  // [LIGHTS_TYPES.mortgageFarEast]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageFarEast],
  // ],
  // [LIGHTS_TYPES.mortgageVillage]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgageVillage],
  // ],
  // [LIGHTS_TYPES.mortgagePrimary]: [
  //   LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.mortgagePrimary],
  // ],

  [LIGHTS_TYPES.project]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.project]],
  [LIGHTS_TYPES.resettlement]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.resettlement],
  ],
  [LIGHTS_TYPES.roads]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roads]],
  [LIGHTS_TYPES.roadsDead]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roadsDead]],
  [LIGHTS_TYPES.roadsRegional]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roadsRegional],
  ],
  [LIGHTS_TYPES.roadsInfrastructure]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.roadsInfrastructure],
  ],
  [LIGHTS_TYPES.sharedConstruction]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.sharedConstruction],
  ],
  [LIGHTS_TYPES.stimul]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.stimul]],
  [LIGHTS_TYPES.water]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.water]],
  [LIGHTS_TYPES.sharedConstruction]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.sharedConstruction],
  ],
  [LIGHTS_TYPES.recoveryVolga]: [
    LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.recoveryVolga],
  ],
  [LIGHTS_TYPES.baikal]: [LIGHT_FIELD_DYNAMICS[LIGHTS_TYPES.baikal]],
};

export const ADDITIONAL_LIGHTS_MAP = [
  [
    { light: LIGHTS_MAP.roads, title: "Светофор №1" },
    { light: LIGHTS_MAP.roadsRegional, title: "Светофор №2" },
    { light: LIGHTS_MAP.roadsInfrastructure, title: "Светофор №3" },
  ],
  [
    { light: LIGHTS_MAP.mortgageGov, title: "Выплата многодетным семьям" },
    { light: LIGHTS_MAP.mortgageGovBenefits, title: "Льготная ипотека" },
    { light: LIGHTS_MAP.mortgageGovFamily, title: "Семейная ипотека" },
    { light: LIGHTS_MAP.mortgageFarEast, title: "Дальневосточная ипотека" },
    { light: LIGHTS_MAP.mortgageVillage, title: "Сельская ипотека" },
  ],
];

export const EXTRA_LIGHTS_MAP = [
  [LIGHTS_MAP.mortgagePrimary],
  [
    LIGHTS_MAP.mortgageGov,
    LIGHTS_MAP.mortgageGovBenefits,
    LIGHTS_MAP.mortgageGovFamily,
    LIGHTS_MAP.mortgageFarEast,
    LIGHTS_MAP.mortgageVillage,
  ],
  [LIGHTS_MAP.roadsDead],
];

export const STICK_LIGHTS_MAP = {
  [LIGHTS_MAP.roads]: [LIGHTS_MAP.roadsDead],
};

export interface AdditionalLight {
  title: string;
  id: string;
}

export interface AdditionalLights {
  selected: AdditionalLight;
  mainLight: string;
  additional: AdditionalLight[];
}

export const useLight = (id: string, withTotal: boolean = false) => {
  const { items, fetching: listFetching } = useAppSelector(
    (state) => state.lights.list
  );
  const { data: totalData, fetching: totalFetching } = useAppSelector(
    (state) => state.lights.total
  );
  const { data: totalRatesData, fetching: totalRatesFetching } = useAppSelector(
    (state) => state.lights.totalRates
  );
  const {
    data: dynamicsData,
    additionalData,
    fetching: dynamicsFetching,
  } = useAppSelector((state) => state.lights.dynamics);

  const { regions } = useRegions();

  const getLightById = useCallback(
    (lightId: string) => items.find((item) => item.id === lightId),
    [items]
  );

  const light = useMemo(() => getLightById(id), [getLightById, id]);

  const getTotalByLightId = useCallback(
    (lightId: string) => totalData?.[lightId],
    [totalData]
  );

  const total = useMemo(() => {
    if (!light) {
      return undefined;
    }

    const ids = [light.id, ...(STICK_LIGHTS_MAP[light.id] ?? [])];

    return ids.reduce(
      (prev, id) => ({ ...prev, ...getTotalByLightId(id) }),
      {} as ILightRow
    );
  }, [light, getTotalByLightId]);

  const additionalLights = useMemo(() => {
    if (!light) {
      return;
    }

    const list = ADDITIONAL_LIGHTS_MAP.find((list) =>
      list.some((list) => list.light === light.id)
    );

    if (!list) {
      return;
    }

    const result: AdditionalLights = {
      selected: { title: list[0].title, id: list[0].light },
      mainLight: list[0].light,
      additional: [],
    };

    return list.reduce((prev, curr, index) => {
      const obj = { title: list[index].title, id: curr.light };

      if (curr.light === light.id) {
        prev.selected = obj;
      } else {
        prev.additional.push(obj);
      }

      return prev;
    }, result);
  }, [light]);

  const isAdditional = Boolean(
    additionalLights && light && additionalLights.mainLight !== light.id
  );

  const totalRates = useMemo(
    () =>
      totalRatesData && light
        ? {
            [LIGHT_RATES.high]:
              totalRatesData[light.id]?.filter(
                (row) => row.rate === LIGHT_RATES.high
              ) ?? [],
            [LIGHT_RATES.middle]:
              totalRatesData[light.id]?.filter(
                (row) => row.rate === LIGHT_RATES.middle
              ) ?? [],
            [LIGHT_RATES.low]:
              totalRatesData[light.id]?.filter(
                (row) => row.rate === LIGHT_RATES.low
              ) ?? [],
          }
        : undefined,
    [light, totalRatesData]
  );

  const getDynamicsData = useCallback(
    (lightId: string, items?: ILightData) => {
      if (!total || dynamicsFetching) {
        return undefined;
      }

      const totalRow = {
        ...total,
        key: REGION_COUNTRY_CODE,
        sn: 0,
        region: getRegion(REGION_COUNTRY_CODE, regions),
      } as ILightTableRow;

      const dynamicsField =
        LIGHT_FIELD_DYNAMICS[
          LIGHTS_MAP_TYPES[lightId as keyof typeof LIGHTS_MAP_TYPES]
        ];

      const sortFactor =
        LIGHT_FIELD_DYNAMICS_SORT[
          LIGHTS_MAP_TYPES[lightId as keyof typeof LIGHTS_MAP_TYPES]
        ] ?? 1;

      const data =
        items && Object.values(items)?.[0]?.[dynamicsField]
          ? (Object.keys(items)
              .sort((regionA, regionB) => {
                if (regionA === REGION_COUNTRY_CODE) {
                  return -1;
                } else if (regionB === REGION_COUNTRY_CODE) {
                  return 1;
                }

                return (
                  sortFactor * (items[regionB][dynamicsField]?.value ?? 0) -
                  sortFactor * (items[regionA][dynamicsField]?.value ?? 0)
                );
              })
              .map((regionId, index) => {
                const key = regionId;
                const sn = index;
                const region = getRegion(regionId, regions);

                return {
                  ...items[regionId],
                  key,
                  sn,
                  region,
                } as ILightTableRow;
              })
              .map((row, index, rows) => ({
                ...row,
                rate: LIGHT_RATE_FUNCS[
                  LIGHTS_MAP_TYPES[lightId as keyof typeof LIGHTS_MAP_TYPES]
                ]?.(row, rows),
              })) as ILightTableRow[])
          : undefined;

      if (withTotal && data) {
        data.unshift(totalRow);
      }

      return data;
    },
    [dynamicsFetching, regions, total, withTotal]
  );

  const dynamics = useMemo(() => {
    return getDynamicsData(additionalLights?.mainLight ?? id, dynamicsData);
  }, [additionalLights, dynamicsData, getDynamicsData, id]);

  const additionalDynamics = useMemo(
    () =>
      isAdditional
        ? getDynamicsData(additionalLights!.selected.id, additionalData)
        : ([] as ILightTableRow[]),
    [additionalData, additionalLights, getDynamicsData, isAdditional]
  );

  return {
    getLightById,
    light,
    additionalLights,
    isAdditional,
    getTotalByLightId,
    total,
    totalRates,
    dynamics,
    additionalDynamics,
    fetching: listFetching || totalFetching,
    listFetching,
    totalFetching,
    totalRatesFetching,
    dynamicsFetching,
    coloredColumns: light ? LIGHT_COLORED_COLS[light.type] ?? [] : [],
  };
};

export const useLightByType = (type: LIGHTS_TYPES) => {
  const { items } = useAppSelector((state) => state.lights.list);

  return items.find((item) => item.type === type);
};
