import { Button, Card, Spin, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ILightIndicator, ILightTableRow } from "../../../../core/scheme/olap";
import { useRouter } from "../../../../hooks/useRouter";
import { valueFromEvent } from "../../../../lib/events";
import { formatNumber } from "../../../../lib/number";
import { LIGHT_RATES } from "../../../../redux/slices/lights/api/rate/rate";
import {
  LIGHT_FACT,
  LIGHT_FIELD_DYNAMICS,
  LIGHT_FIELD_DYNAMICS_SORT,
  LIGHT_PLAN,
  useLight,
} from "../../../../redux/slices/lights/hooks/useLight";
import { calcPlanFactSegments } from "../../../../redux/slices/lights/utils";
import { ButtonGroup } from "../../../ButtonGroup/ButtonGroup";
import { CardHeader } from "../../../CardHeader/CardHeader";
import { Empty } from "../../../Empty";
import {
  ArrowDirection,
  ProgressBar,
  ProgressColors,
} from "../../../ProgressBar/ProgressBar";
import { TextValue } from "../../../TextValue/TextValue";
import * as styles from "../../Dynamics.module.scss";

export const PROGRESS_COLORS_BY_RATE = {
  [LIGHT_RATES.high]: ProgressColors.green,
  [LIGHT_RATES.middle]: ProgressColors.orange,
  [LIGHT_RATES.low]: ProgressColors.red,
};

export interface IDynamicsRatingBaseProps {
  id: string;
  dynamicsField?: string;
  fractionDigits?: number;
  fieldPrevPeriod?: string;
  valueText?: string;
}

export const DynamicsRatingBase: FC<IDynamicsRatingBaseProps> = ({
  id,
  dynamicsField,
  fractionDigits = 0,
  fieldPrevPeriod,
  valueText,
}) => {
  const { getPath } = useRouter();
  const {
    isAdditional,
    additionalLights,
    getLightById,
    dynamics,
    getTotalByLightId,
  } = useLight(id);
  const [visiblePopup, setPopupVisibility] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [sort, setSort] = useState(-1);

  const light = isAdditional
    ? getLightById(additionalLights?.mainLight ?? id)
    : getLightById(id);

  const total = isAdditional
    ? getTotalByLightId(additionalLights?.mainLight ?? id)
    : getTotalByLightId(id);

  const fieldDynamics = light ? LIGHT_FIELD_DYNAMICS[light.type] : undefined;
  const sortFactor = (light && LIGHT_FIELD_DYNAMICS_SORT[light.type]) ?? 1;

  const columns = useMemo(
    () =>
      total
        ? [
            {
              title: "Наименование субъекта",
              dataIndex: "region",
              width: 240,
              render: (field: ILightIndicator) => (
                <Link to={getPath(`/regions/${field.code}`)}>
                  {field.attributeVals.Наименование}
                </Link>
              ),
            },
            {
              title: valueText ?? "",
              dataIndex: dynamicsField ?? LIGHT_FACT[light!.type](total).code,
              width: 140,
              render: (field: ILightIndicator) => (
                <TextValue
                  value={formatNumber(
                    field?.value,
                    field?.attributeVals.UNIT,
                    fractionDigits
                  )}
                />
              ),
            },
            {
              title: "Прирост",
              dataIndex: fieldDynamics,
              width: 20,
              render: (field: ILightIndicator, row: ILightTableRow) => {
                return (
                  <TextValue extraValue={field?.value} extraRate={row.rate} />
                );
              },
            },
            {
              title: "",
              dataIndex: "",
              key: "x",
              width: "50%",
              render: (field: ILightIndicator, row: ILightTableRow) => {
                const color = PROGRESS_COLORS_BY_RATE[row.rate!];
                const [minValue, maxValue, currentValue] = calcPlanFactSegments(
                  LIGHT_PLAN[light!.type](row)?.value,
                  LIGHT_FACT[light!.type](row)?.value,
                  fieldPrevPeriod
                    ? row[fieldPrevPeriod]?.value
                    : LIGHT_FACT[light!.type](row)?.value
                );

                return (
                  <ProgressBar
                    segments={[
                      {
                        color,
                        value: minValue,
                      },
                      {
                        color,
                        value: currentValue - minValue,
                        arrow: true,
                        arrowTransparent: color === ProgressColors.red,
                        arrowDirection:
                          color === ProgressColors.green
                            ? ArrowDirection.right
                            : ArrowDirection.left,
                      },
                    ]}
                    max={maxValue}
                  />
                );
              },
            },
          ]
        : [],
    [
      dynamicsField,
      fieldDynamics,
      fieldPrevPeriod,
      fractionDigits,
      getPath,
      light,
      total,
      valueText,
    ]
  );

  useEffect(() => {
    if (fieldDynamics && dynamics) {
      setTableData(
        dynamics
          .map((row) => ({ ...row, key: `${row.sn}` } as ILightTableRow))
          .filter((row) => {
            return sort === sortFactor
              ? row.rate === LIGHT_RATES.high
              : row.rate === LIGHT_RATES.low;
          })
          .sort((a, b) =>
            sort === 1
              ? b[fieldDynamics]?.value - a[fieldDynamics]?.value
              : a[fieldDynamics]?.value - b[fieldDynamics]?.value
          )
      );
    }
  }, [dynamics, fieldDynamics, sort, sortFactor]);

  const shortTableData = useMemo(() => tableData.slice(0, 5), [tableData]);

  const openPopup = useCallback(
    () => setPopupVisibility(true),
    [setPopupVisibility]
  );

  const closePopup = useCallback(
    () => setPopupVisibility(false),
    [setPopupVisibility]
  );

  return (
    <Card
      className={styles.card2}
      title={
        <CardHeader
          title="Динамика субъектов РФ в отчетном периоде (%)"
          right={
            <ButtonGroup
              value={sort}
              buttons={[
                { value: -1, children: "Топ-5 в красной зоне" },
                { value: 1, children: "Топ-5 лучших" },
              ]}
              onChange={valueFromEvent(setSort, Number)}
            />
          }
        />
      }
    >
      {dynamics ? (
        <>
          <Table
            columns={columns as any}
            dataSource={shortTableData}
            pagination={false}
            locale={{
              emptyText: <Empty />,
            }}
          />
          <Button type="primary" onClick={openPopup}>
            Посмотреть все
          </Button>
          <Modal
            visible={visiblePopup}
            width="min(900px, 50%)"
            wrapClassName={styles.card2}
            footer={false}
            onCancel={closePopup}
            title={
              sort === -1
                ? "Субъекты РФ в красной зоне в отчетном периоде (%)"
                : "Субъекты РФ в зеленой зоне в отчетном периоде (%)"
            }
          >
            <Table
              columns={columns as any}
              dataSource={tableData}
              pagination={false}
              locale={{
                emptyText: <Empty />,
              }}
            />
          </Modal>
        </>
      ) : (
        <div className={styles.loader}>
          <Spin />
        </div>
      )}
    </Card>
  );
};
