import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IPreparedOlapResponseDimensionItemDim } from "../../../core/scheme/olap";
import { prepareAttributes } from "../lights/utils";
import { getRegions, REGIONS_STARTER } from "./api/client";

export interface IRegionsState {
  items?: IPreparedOlapResponseDimensionItemDim[];
  fetching: boolean;
}

export const initialState: IRegionsState = {
  items: undefined,
  fetching: false,
};

export const extraActions = {
  get: createAsyncThunk(
    "lights/regions",
    async (districts: string[]) => await getRegions(districts)
  ),
};

const slice = createSlice({
  name: "lightsRegions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = undefined;
        state.fetching = true;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.items = [
          REGIONS_STARTER,
          ...action.payload.map((item) => ({
            ...item,
            attributeVals: prepareAttributes(item.attributeVals),
          })),
        ];
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
