import {
  getOperand,
  olapRequestData2,
  olapRequestDimensionData,
} from "../../../../core/api/core";
import {
  DATA_ACTUAL_VALUE,
  DATA_PERIOD_VALUE,
  DATA_SOURCE_VALUE,
  FILTER_OPERAND_TYPE,
  FILTER_TYPE,
  getModel,
  IDataFilterWithOperands,
} from "../../../../core/scheme/olap";
import { REGION_COUNTRY_CODE } from "../../regions/api/scheme";

export const getList = () => {
  const model = getModel();

  return olapRequestDimensionData(model.dimId, {
    modelUuid: model.uuid,
    dimensionId: model.dimId,
    // dataSort: [{ dim_id: model.dimId, sortField: "name", sortOrder: SORT.ASC }],
    dataFilter: {
      type: FILTER_TYPE.STARTER,
      dim_id: model.dimId,
    },
    includeAttributesByCodes: [
      "NAME",
      "SHORT_NAME",
      "SORT_ORDER",
      "Наименование",
    ],
  });
};

export const getLightsTotals = ({
  ids,
  regionIds = [REGION_COUNTRY_CODE],
  periodIds = [DATA_PERIOD_VALUE],
}: {
  ids: string[];
  regionIds?: string[];
  periodIds?: string[];
}) => {
  const model = getModel();

  return olapRequestData2(
    [model.dataRegionId, model.dataPeriodId, model.dimId],
    model.indicatorId,
    {
      modelUuid: model.uuid,
      indicatorId: model.indicatorId,
      includeGaps: true,
      dimensions: [
        {
          id: model.dataRegionId,
          includeItems: true,
          includeAttributesByCodes: [
            "NAME",
            "SHORT_NAME",
            "SORT_ORDER",
            "Наименование",
          ],
        },
        {
          id: model.dataPeriodId,
          includeItems: true,
          includeAttributesByCodes: [
            "NAME",
            "SHORT_NAME",
            "SORT_ORDER",
            "REPORT_DATE",
          ],
        },
        {
          id: model.dimId,
          includeItems: true,
          includeAttributesByCodes: [
            "NAME",
            "SHORT_NAME",
            "SORT_ORDER",
            "Наименование",
          ],
        },
      ],
      indicators: {
        id: model.indicatorId,
        items: [{ id: model.sumIndicatorId, aggregationFunction: "sum" }],
      },
      // dataSort: [{ dim_id: model.dimId, sortField: "name", sortOrder: SORT.ASC }],
      dataFilter: {
        type: FILTER_TYPE.AND,
        operands: [
          // add parentId attribute
          getOperand({
            type: FILTER_OPERAND_TYPE.LEVEL,
            dim_id: model.dimId,
            levels: [1, 2],
            rootIds: ids,
            showAllLevelElements: false,
            showChildren: false,
            showLeafsOnly: false,
          }),

          getOperand({
            type: FILTER_OPERAND_TYPE.EQ,
            operands: [
              getOperand({
                type: FILTER_OPERAND_TYPE.DIM,
                id: model.dataSourceId,
              }),
              getOperand({
                type: FILTER_OPERAND_TYPE.CONST,
                values: [DATA_SOURCE_VALUE],
              }),
            ],
          }),

          getOperand({
            type: FILTER_OPERAND_TYPE.EQ,
            operands: [
              getOperand({
                type: FILTER_OPERAND_TYPE.DIM,
                id: model.dataActualId,
              }),
              getOperand({
                type: FILTER_OPERAND_TYPE.CONST,
                values: [DATA_ACTUAL_VALUE],
              }),
            ],
          }),

          getOperand({
            type: FILTER_OPERAND_TYPE.LEVEL,
            dim_id: model.dataPeriodId,
            levels: [0, 1],
            rootIds: periodIds,
            showAllLevelElements: false,
            showChildren: false,
            showLeafsOnly: false,
          }),

          getOperand({
            type: FILTER_OPERAND_TYPE.EQ,
            operands: [
              getOperand({
                type: FILTER_OPERAND_TYPE.DIM,
                id: model.dataRegionId,
              }),
              getOperand({
                type: FILTER_OPERAND_TYPE.CONST,
                values: regionIds,
              }),
            ],
          }),
        ],
      },
    }
  );
};

export interface ILightDynamicsParams {
  parentIds?: string[];
  regionIds?: string[];
  regionLevels?: number[];
  fieldIds?: string[];
  fieldLevels?: number[];
  periodIds?: string[];
  actualData?: boolean;
}

export const getLightDynamics = (
  params: ILightDynamicsParams | ILightDynamicsParams[]
) => {
  const model = getModel();
  const arr = Array.isArray(params) ? params : [params];

  const operands: IDataFilterWithOperands["operands"][] = [];

  let hasRegions = false;
  let hasPeriod = false;

  arr.forEach(
    ({
      parentIds,
      regionIds,
      regionLevels,
      fieldIds,
      fieldLevels,
      periodIds = [DATA_PERIOD_VALUE],
      actualData = true,
    }) => {
      const op = [
        getOperand({
          type: FILTER_OPERAND_TYPE.EQ,
          operands: [
            getOperand({
              type: FILTER_OPERAND_TYPE.DIM,
              id: model.dataSourceId,
            }),
            getOperand({
              type: FILTER_OPERAND_TYPE.CONST,
              values: [DATA_SOURCE_VALUE],
            }),
          ],
        }),
      ];

      if (actualData) {
        op.push(
          getOperand({
            type: FILTER_OPERAND_TYPE.EQ,
            operands: [
              getOperand({
                type: FILTER_OPERAND_TYPE.DIM,
                id: model.dataActualId,
              }),
              getOperand({
                type: FILTER_OPERAND_TYPE.CONST,
                values: [DATA_ACTUAL_VALUE],
              }),
            ],
          })
        );
      }

      if (periodIds) {
        op.push(
          getOperand({
            type: FILTER_OPERAND_TYPE.LEVEL,
            dim_id: model.dataPeriodId,
            levels: [0, 1],
            rootIds: periodIds,
            showAllLevelElements: false,
            showChildren: false,
            showLeafsOnly: false,
          })
        );

        hasPeriod = true;
      }

      if (parentIds) {
        op.push(
          getOperand({
            type: FILTER_OPERAND_TYPE.LEVEL,
            dim_id: model.dimId,
            levels: [1, 2],
            rootIds: parentIds!,
            showAllLevelElements: false,
            showChildren: false,
            showLeafsOnly: false,
          })
        );
      } else {
        op.push(
          getOperand({
            type: FILTER_OPERAND_TYPE.LEVEL,
            dim_id: model.dimId,
            levels: fieldLevels ?? [2],
            rootIds: fieldIds || ["0"],
            showAllLevelElements: false,
            showChildren: false,
            showLeafsOnly: false,
          })
        );
      }

      if (regionIds) {
        op.push(
          getOperand({
            type: FILTER_OPERAND_TYPE.LEVEL,
            dim_id: model.dataRegionId,
            levels: regionLevels ?? [],
            rootIds: regionIds,
            showAllLevelElements: false,
            showChildren: false,
            showLeafsOnly: false,
          })
        );

        hasRegions = true;
      }

      operands.push(op);
    }
  );

  const dimIds = [model.dimId];
  const dimensions = [
    {
      id: model.dimId,
      includeItems: true,
      includeAttributesByCodes: [
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
        "Наименование",
      ],
    },
  ];

  if (hasPeriod) {
    dimIds.unshift(model.dataPeriodId);
    dimensions.unshift({
      id: model.dataPeriodId,
      includeItems: true,
      includeAttributesByCodes: [
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
        "REPORT_DATE",
      ],
    });
  }

  if (hasRegions) {
    dimIds.unshift(model.dataRegionId);
    dimensions.unshift({
      id: model.dataRegionId,
      includeItems: true,
      includeAttributesByCodes: [
        "NAME",
        "SHORT_NAME",
        "SORT_ORDER",
        "Наименование",
      ],
    });
  }

  try {
    olapRequestData2(dimIds, model.indicatorId, {
      modelUuid: model.uuid,
      indicatorId: model.indicatorId,
      includeGaps: true,
      dimensions,
      indicators: {
        id: model.indicatorId,
        items: [{ id: model.sumIndicatorId, aggregationFunction: "sum" }],
      },
      // dataSort: [{ dim_id: DIM_ID, sortField: "name", sortOrder: SORT.ASC }],
      dataFilter:
        operands.length > 1
          ? {
              type: FILTER_TYPE.OR,
              operands: operands.map((op) => ({
                type: FILTER_TYPE.AND,
                operands: op,
              })),
            }
          : { type: FILTER_TYPE.AND, operands: operands[0] },
    });
  } catch (e) {
    console.error(e);
  }

  return olapRequestData2(dimIds, model.indicatorId, {
    modelUuid: model.uuid,
    indicatorId: model.indicatorId,
    includeGaps: true,
    dimensions,
    indicators: {
      id: model.indicatorId,
      items: [{ id: model.sumIndicatorId, aggregationFunction: "sum" }],
    },
    // dataSort: [{ dim_id: DIM_ID, sortField: "name", sortOrder: SORT.ASC }],
    dataFilter:
      operands.length > 1
        ? {
            type: FILTER_TYPE.OR,
            operands: operands.map((op) => ({
              type: FILTER_TYPE.AND,
              operands: op,
            })),
          }
        : { type: FILTER_TYPE.AND, operands: operands[0] },
  });
};
