import { identity } from "./fn";

export const valueFromEvent =
  <V extends string, R = string, T = any>(
    fn: (val: R) => T,
    transform: (v: V) => R = identity as any
  ) =>
  (ev: { target: { value?: V } } | { currentTarget: { value: V } }) => {
    const element = "target" in ev ? ev.target : ev.currentTarget;
    return fn(transform((element?.value ?? "") as V));
  };
